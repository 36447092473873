import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material"
import { useRef } from "react"

const handleEntered = () => {
  // The `autoFocus` is not used because, if used, the same Enter that saves
  // the cell triggers "No". Instead, we manually focus the "No" button once
  // the dialog is fully open.
  // noButtonRef.current?.focus();
}

export function ConfirmDialog({ params = null, setOpen }) {
  const noButtonRef = useRef(null)

  if (!params) {
    return null
  }

  const handleNo = () => {
    setOpen(false)
  }

  const handleYes = () => {
    setOpen(false)
    params.yesAction()
  }

  return (
    <Dialog maxWidth="xs" TransitionProps={{ onEntered: handleEntered }} open={!!params}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogActions>
        <Button ref={noButtonRef} onClick={handleNo}>
          No
        </Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}
