const testData = require("./testData.json")

const testJSONFor = (url) => {
  console.log(`GET test JSON for ${url}`)
  if (url === "/me") {
    return testData.me
  }
  if (url === "/settings") {
    return testData.settings
  }
  if (url === "/guild/members") {
    return testData.guildMembers
  }
  if (url === "/guild/members?sync=true") {
    return testData.guildMembersSync
  }
  if (url === "/travian-accounts") {
    return testData.travianAccounts
  }
  if (url === "/travian-accounts/capitals") {
    return testData.capitals
  }
  if (/^\/troops\/[a-z\-0-9]+\/troops$/.test(url)) {
    return testData.troops
  }
  if (url === "/troops/troops") {
    return testData.troopsOverview
  }
  if (url === "/travian-accounts/troops") {
    return testData.troopsOverview
  }
  if (url === "/alliances/all") {
    return testData.allAlliances
  }
  if (url === "/alliances") {
    return testData.alliances
  }
  if (url === "/statistics/guild") {
    return testData.guildStatistics
  }
  if (url === "/v2/incomings/active") {
    return [
      {
        waves: 4,
        call: "-",
        notes: "cap, 17s",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 32 * 60 * 60 * 1000),
        attacker: {
          yCoord: -33,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Bukvice",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "03-Žaludovica",
          allyName: "NEČUM",
          population: 885,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 978000000
          },
          lowerCasePlayerName: "bukvice",
          coordId: 93516,
          xCoord: -118,
          tribe: 1,
          allyId: 75,
          villageId: 32942,
          timestamp: 0,
          playerId: 4746,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 339000000
          }
        },
        id: "30782af2-bdf2-5ff2-8b37-b2c055c812d8",
        saveTime: "2023-12-07T10:25:19.888Z",
        lastChecked: "2023-12-07T10:20:16.603Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "jaegermeister",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "00",
          allyName: "TCORP",
          population: 1061,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 891000000
          },
          lowerCasePlayerName: "jaegermeister",
          coordId: 86002,
          xCoord: -13,
          tribe: 1,
          allyId: 86,
          villageId: 25793,
          timestamp: 0,
          playerId: 368,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 319000000
          }
        }
      },
      {
        waves: 1,
        call: "-",
        notes: "cap, 17s",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000 + 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "0b018939-8275-5e5a-8098-75e3788f3099",
        saveTime: "2023-12-07T15:02:21.527Z",
        lastChecked: "2023-12-07T10:02:17.517Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "jaegermeister",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "00",
          allyName: "TCORP",
          population: 1061,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 891000000
          },
          lowerCasePlayerName: "jaegermeister",
          coordId: 86002,
          xCoord: -13,
          tribe: 1,
          allyId: 86,
          villageId: 25793,
          timestamp: 0,
          playerId: 368,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 319000000
          }
        }
      },
      {
        waves: 3,
        call: "-",
        notes: "cap, 17s",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "5d4fb0e9-5be1-54e1-ae91-05242365d44d",
        saveTime: "2023-12-07T15:02:21.535Z",
        lastChecked: "2023-12-07T10:02:17.517Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "jaegermeister",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "00",
          allyName: "TCORP",
          population: 1061,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 891000000
          },
          lowerCasePlayerName: "jaegermeister",
          coordId: 86002,
          xCoord: -13,
          tribe: 1,
          allyId: 86,
          villageId: 25793,
          timestamp: 0,
          playerId: 368,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 319000000
          }
        }
      },
      {
        waves: 4,
        call: "-",
        notes: "Capital, Lvl 15 fields",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "53a11dff-7b64-5b40-8c1e-d7bf27ecab12",
        saveTime: "2023-12-07T15:24:36.840Z",
        lastChecked: "1970-01-01T00:00:00.000Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Blackwood",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02",
          allyName: "TCORP",
          population: 795,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 891000000
          },
          lowerCasePlayerName: "blackwood",
          coordId: 86004,
          xCoord: -11,
          tribe: 1,
          allyId: 86,
          villageId: 25705,
          timestamp: 0,
          playerId: 369,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 319000000
          }
        }
      },
      {
        waves: 3,
        call: "-",
        notes: "Cap",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "04b711aa-88a4-50d8-941b-e62938c2eff0",
        saveTime: "2023-12-07T12:31:06.358Z",
        lastChecked: "2023-12-07T04:31:04.183Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Bixette",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "01. Sulander",
          allyName: "TC0RP",
          population: 934,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 594000000
          },
          lowerCasePlayerName: "bixette",
          coordId: 74829,
          xCoord: 42,
          tribe: 1,
          allyId: 272,
          villageId: 26506,
          timestamp: 0,
          playerId: 2424,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 294000000
          }
        }
      },
      {
        waves: 1,
        call: "-",
        notes: "Cap",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "99d6f9a1-8875-5351-b9ad-9ee683d6526c",
        saveTime: "2023-12-07T12:31:06.366Z",
        lastChecked: "2023-12-07T04:31:04.183Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 14,
          capital: true,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Bixette",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "01. Sulander",
          allyName: "TC0RP",
          population: 934,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 594000000
          },
          lowerCasePlayerName: "bixette",
          coordId: 74829,
          xCoord: 42,
          tribe: 1,
          allyId: 272,
          villageId: 26506,
          timestamp: 0,
          playerId: 2424,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 294000000
          }
        }
      },
      {
        waves: 2,
        call: "-",
        notes: "WW, 2k SD",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "292a1a72-fb6f-53ca-b018-505e9cc18d83",
        saveTime: "2023-12-07T13:12:21.581Z",
        lastChecked: "2023-12-07T13:02:08.105Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 3,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Beelzebub",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "Hell",
          allyName: "TCORP",
          population: 554,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 716000000
          },
          lowerCasePlayerName: "beelzebub",
          coordId: 79204,
          xCoord: 6,
          tribe: 2,
          allyId: 86,
          villageId: 10,
          timestamp: 0,
          playerId: 724,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 300000000
          }
        }
      },
      {
        waves: 1,
        call: "-",
        notes: "WW, 2k SD",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "eecccb97-7c27-5892-86fd-c04d2a0fe127",
        saveTime: "2023-12-07T13:12:21.589Z",
        lastChecked: "2023-12-07T13:02:08.105Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 3,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Beelzebub",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "Hell",
          allyName: "TCORP",
          population: 554,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 716000000
          },
          lowerCasePlayerName: "beelzebub",
          coordId: 79204,
          xCoord: 6,
          tribe: 2,
          allyId: 86,
          villageId: 10,
          timestamp: 0,
          playerId: 724,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 300000000
          }
        }
      },
      {
        waves: 1,
        call: "-",
        notes: "WW, 2k SD",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -118,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Tomihowinday",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "02) Zan day",
          allyName: "NEČU",
          population: 949,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907306,
            _nanoseconds: 513000000
          },
          lowerCasePlayerName: "tomihowinday",
          coordId: 127657,
          xCoord: -62,
          tribe: 1,
          allyId: 20,
          villageId: 30314,
          timestamp: 0,
          playerId: 3662,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 378000000
          }
        },
        id: "b9e27a85-18f7-5ca2-980e-0d7f68bf21d8",
        saveTime: "2023-12-07T13:12:21.597Z",
        lastChecked: "2023-12-07T13:02:08.105Z",
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 3,
          capital: false,
          travianUrl: "ts5.x1.europe.travian.com",
          playerName: "Beelzebub",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "Hell",
          allyName: "TCORP",
          population: 554,
          unused1: "NULL",
          createdAt: {
            _seconds: 1701907305,
            _nanoseconds: 716000000
          },
          lowerCasePlayerName: "beelzebub",
          coordId: 79204,
          xCoord: 6,
          tribe: 2,
          allyId: 86,
          villageId: 10,
          timestamp: 0,
          playerId: 724,
          updatedAt: {
            _seconds: 1701907304,
            _nanoseconds: 300000000
          }
        }
      },
      {
        waves: 4,
        call: "Def",
        notes: "600k wall",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        defCallNotes: "Def call notes!",
        defCallPosted: new Date(),
        attacker: {
          yCoord: -106,
          capital: false,
          playerName: "Muhbear",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "19^",
          allyName: ".V.",
          population: 802,
          unused1: "NULL",
          coordId: 123090,
          xCoord: 183,
          tribe: 2,
          allyId: 24,
          villageId: 28767,
          playerId: 2095,
          updatedAt: {
            _nanoseconds: 90000000,
            _seconds: 1679842484
          }
        },
        id: "0db5b134-e203-5802-9a11-9a5ac5ff0813",
        saveTime: new Date(),
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: 21,
          capital: false,
          playerName: "REO Speedwagon",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "10",
          allyName: "80s",
          population: 952,
          unused1: "NULL",
          coordId: 71992,
          xCoord: 12,
          tribe: 2,
          allyId: 2,
          villageId: 27131,
          playerId: 302,
          updatedAt: {
            _nanoseconds: 90000000,
            _seconds: 1679842484
          }
        }
      },
      {
        waves: 4,
        call: "Def",
        notes: "",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
        attacker: {
          yCoord: -60,
          capital: false,
          playerName: "Muhbear",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "04",
          allyName: ".V.",
          population: 913,
          unused1: "NULL",
          coordId: 104548,
          xCoord: 87,
          tribe: 2,
          allyId: 24,
          villageId: 23013,
          playerId: 2095,
          updatedAt: {
            _seconds: 1679842484,
            _nanoseconds: 90000000
          }
        },
        id: "e82b8bfd-5bb1-5f0a-a160-728585f647af",
        saveTime: new Date(),
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -4,
          capital: false,
          playerName: "REO Speedwagon",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "Awesome WW of M.A.",
          allyName: "80s",
          population: 505,
          unused1: "NULL",
          coordId: 82010,
          xCoord: 5,
          tribe: 2,
          allyId: 2,
          villageId: 11,
          playerId: 302,
          updatedAt: {
            _seconds: 1679842484,
            _nanoseconds: 90000000
          }
        }
      },
      {
        waves: 4,
        call: "Fake",
        notes: "hero gear changed",
        arteSpeed: 1,
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000),
        attacker: {
          yCoord: -60,
          capital: false,
          playerName: "Muhbear",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "04",
          allyName: ".V.",
          population: 913,
          unused1: "NULL",
          coordId: 104548,
          xCoord: 87,
          tribe: 2,
          allyId: 24,
          villageId: 23013,
          playerId: 2095,
          updatedAt: {
            _seconds: 1679842484,
            _nanoseconds: 90000000
          }
        },
        id: "e82b8bfd-5bb1-5f0a-a160-728585f647ae",
        saveTime: new Date(),
        unitSpeed: 3,
        tournamentSquare: 0,
        defender: {
          yCoord: -4,
          capital: false,
          playerName: "REO Speedwagon",
          unused2: "NULL",
          unused3: "NULL",
          villageName: "Awesome WW of M.A.",
          allyName: "80s",
          population: 505,
          unused1: "NULL",
          coordId: 82010,
          xCoord: 5,
          tribe: 2,
          allyId: 2,
          villageId: 11,
          playerId: 302,
          updatedAt: {
            _seconds: 1679842484,
            _nanoseconds: 90000000
          }
        }
      }
    ]
  }
  if (url === "/def-calls/active") {
    return [
      {
        id: "foo123",
        guildId: "123",
        incomingId: "0db5b134-e203-5802-9a11-9a5ac5ff0813",
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000).toISOString(),
        amount: 16000,
        travianAccountId: "5569ba74-7cc2-4b8f-aa64-d143996e40ad",
        notes: "def call notes for Vaeltaja",
        travianAccount: {
          notes: "",
          alliance: "KIRKA",
          players: "",
          tribe: "Gaul",
          accountType: "Support",
          name: "Vaeltaja",
          discordUserIds: ["487660461312180225", "315404543619891200"],
          id: "5569ba74-7cc2-4b8f-aa64-d143996e40ad",
          discordUsers: [
            {
              joined_at: "2023-03-02T21:42:25.205000+00:00",
              nick: "GoNu",
              premium_since: null,
              communication_disabled_until: null,
              pending: false,
              roles: [
                "1091086657064808488",
                "1102372364261523466",
                "1103002110158721054",
                "1081594065235234877",
                "1103002171974369374"
              ],
              flags: 0,
              deaf: false,
              mute: false,
              avatar: null,
              user: {
                banner_color: null,
                accent_color: null,
                global_name: "appelstroopwafel",
                flags: 0,
                banner: null,
                id: "487660461312180225",
                avatar: null,
                public_flags: 0,
                username: "appelstroopwafel",
                discriminator: "0",
                avatar_decoration: null
              }
            },
            {
              joined_at: "2023-04-26T23:14:32.110000+00:00",
              nick: null,
              premium_since: null,
              communication_disabled_until: null,
              pending: false,
              roles: ["1102372364261523466"],
              flags: 0,
              deaf: false,
              mute: false,
              avatar: null,
              user: {
                banner_color: null,
                accent_color: null,
                global_name: "Menfi",
                avatar_decoration_data: null,
                flags: 0,
                banner: null,
                id: "315404543619891200",
                avatar: "1c31b4efde04c609a9afbd5ad7b633e4",
                public_flags: 0,
                username: ".menfi",
                discriminator: "0"
              },
              timestamp: 1695382770545
            }
          ],
          group: "",
          capital: {
            yCoord: 79,
            capital: true,
            playerName: "Vaeltaja",
            unused2: "NULL",
            unused3: "NULL",
            villageName: "01 Hoppu",
            allyName: "KIRKA",
            population: 268,
            unused1: "NULL",
            coordId: 48770,
            xCoord: 48,
            tribe: 3,
            allyId: 115,
            villageId: 19742,
            playerId: 2650,
            updatedAt: { _seconds: 1695855698, _nanoseconds: 53000000 }
          }
        }
      },
      {
        id: "foo124",
        guildId: "123",
        incomingId: "0db5b134-e203-5802-9a11-9a5ac5ff0813",
        arrival: new Date(new Date().getTime() + 18 * 60 * 60 * 1000).toISOString(),
        amount: 32000,
        travianAccountId: "54ff93e8-fc06-46b6-af0b-52f0d3f61791",
        notes: "def call notes for Vätys",
        travianAccount: {
          notes: "Small arte sweep",
          alliance: "X",
          players: "",
          tribe: "Gaul",
          accountType: "Def",
          name: "Vätys",
          discordUserIds: ["116352922614366215"],
          id: "54ff93e8-fc06-46b6-af0b-52f0d3f61791",
          discordUsers: [
            {
              joined_at: "2023-04-04T18:10:19.719000+00:00",
              nick: null,
              premium_since: null,
              communication_disabled_until: null,
              pending: false,
              roles: [
                "1091086657064808488",
                "1125827552720732301",
                "1081641848151478342"
              ],
              flags: 0,
              deaf: false,
              mute: false,
              avatar: null,
              user: {
                banner_color: null,
                accent_color: null,
                global_name: "Kalkkuna",
                flags: 512,
                banner: null,
                id: "116352922614366215",
                avatar: "d044311bc2269bb4b58097937ca915b4",
                public_flags: 512,
                username: "calkkuna",
                discriminator: "0",
                avatar_decoration: null
              }
            }
          ],
          group: "2",
          capital: {
            yCoord: 49,
            capital: true,
            playerName: "Vätys",
            unused2: "NULL",
            unused3: "NULL",
            villageName: "99",
            allyName: "X",
            population: 289,
            unused1: "NULL",
            coordId: 60823,
            xCoord: 71,
            tribe: 3,
            allyId: 25,
            villageId: 19388,
            playerId: 2276,
            updatedAt: { _seconds: 1695855698, _nanoseconds: 252000000 }
          }
        }
      }
    ]
  }
  if (url === "/open-def-calls/active") {
    return [
      {
        id: "string",
        guildId: "123",
        incomingId: "e82b8bfd-5bb1-5f0a-a160-728585f647af",
        arrival: new Date(new Date().getTime() + 2 * 60 * 60 * 1000),
        amount: 600000,
        notes: "string"
      }
    ]
  }
  console.log(`no test data condition matched ${url}`)
  return null
}

export { testJSONFor }
