import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListSubheader from "@mui/material/ListSubheader"
import { Divider, List } from "@mui/material"

import DashboardIcon from "@mui/icons-material/EventAvailable"
import SettingsIcon from "@mui/icons-material/Settings"
import RosterIcon from "@mui/icons-material/People"
import TroopsOverviewIcon from "@mui/icons-material/ListAlt"
import AnalyticsIcon from "@mui/icons-material/Analytics"

import TroopsIcon from "@mui/icons-material/Fort"
import LeaderboardIcon from "@mui/icons-material/Leaderboard"
// import HeroIcon from "@mui/icons-material/SensorOccupied"
import IncomingsIcon from "@mui/icons-material/CrisisAlert"
// import DefTasksIcon from "@mui/icons-material/HealthAndSafety"
// import PendingActionsIcon from "@mui/icons-material/PendingActions"
// import ScoutTasksIcon from "@mui/icons-material/ManageSearch"

import IncomingsViewIcon from "@mui/icons-material/Security"
import DefPlanIcon from "@mui/icons-material/AddModerator"
// import DefCommandsIcon from "@mui/icons-material/GppMaybe"
// import ScoutCommandsIcon from "@mui/icons-material/Policy"

import SportsMmaIcon from "@mui/icons-material/SportsMma"
// import MonitorHeartIcon from "@mui/icons-material/MonitorHeart"
import TroubleshootIcon from "@mui/icons-material/Troubleshoot"

const shouldShowSection = (section, { roles }) => {
  let allowedAccessTypes = []
  switch (section) {
    case "admin":
      allowedAccessTypes = ["ADMIN"]
      break

    case "all":
      allowedAccessTypes = ["ADMIN", "MEMBER", "TECH"]
      break

    case "member":
      allowedAccessTypes = ["ADMIN", "MEMBER"]
      break

    case "dc":
      allowedAccessTypes = ["ADMIN", "DC"]
      break

    case "oc":
      allowedAccessTypes = ["ADMIN", "OC"]
      break

    default:
      return false
  }
  return (roles || []).some((role) => allowedAccessTypes.includes(role.accessType))
}

export default function SideNav({ me, setActiveContent }) {
  return (
    <List component="nav" dense>
      <>
        <ListItemButton onClick={() => setActiveContent("Dashboard")}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </>
      {shouldShowSection("admin", me) || shouldShowSection("dc", me) ? (
        <>
          <ListItemButton onClick={() => setActiveContent("Settings")}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </>
      ) : null}
      {shouldShowSection("member", me) ? (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader component="div" inset>
            Alliance
          </ListSubheader>
          <ListItemButton onClick={() => setActiveContent("Roster")}>
            <ListItemIcon>
              <RosterIcon />
            </ListItemIcon>
            <ListItemText primary="Roster" />
          </ListItemButton>
        </>
      ) : null}
      {shouldShowSection("admin", me) ? (
        <>
          <ListItemButton onClick={() => setActiveContent("Troops overview")}>
            <ListItemIcon>
              <TroopsOverviewIcon />
            </ListItemIcon>
            <ListItemText primary="Troops overview" />
          </ListItemButton>
        </>
      ) : null}
      {shouldShowSection("member", me) ? (
        <>
          <ListItemButton onClick={() => setActiveContent("Statistics overview")}>
            <ListItemIcon>
              <AnalyticsIcon />
            </ListItemIcon>
            <ListItemText primary="Statistics overview" />
          </ListItemButton>
        </>
      ) : null}
      {shouldShowSection("all", me) ? (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader component="div" inset>
            Account
          </ListSubheader>
          <ListItemButton onClick={() => setActiveContent("Troops")}>
            <ListItemIcon>
              <TroopsIcon />
            </ListItemIcon>
            <ListItemText primary="Troops" />
          </ListItemButton>
          <ListItemButton onClick={() => setActiveContent("Statistics")}>
            <ListItemIcon>
              <LeaderboardIcon />
            </ListItemIcon>
            <ListItemText primary="Statistics" />
          </ListItemButton>
          {/* <ListItemButton disabled>
            <ListItemIcon>
              <HeroIcon />
            </ListItemIcon>
            <ListItemText primary="Hero" />
          </ListItemButton> */}
          <ListItemButton onClick={() => setActiveContent("Report incomings")}>
            <ListItemIcon>
              <IncomingsIcon />
            </ListItemIcon>
            <ListItemText primary="Incomings" />
          </ListItemButton>
          {/* <ListItemButton disabled>
            <ListItemIcon>
              <DefTasksIcon />
            </ListItemIcon>
            <ListItemText primary="Defensive tasks" />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>
              <PendingActionsIcon />
            </ListItemIcon>
            <ListItemText primary="Ops commands" />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>
              <ScoutTasksIcon />
            </ListItemIcon>
            <ListItemText primary="Scouting tasks" />
          </ListItemButton> */}
        </>
      ) : null}
      {shouldShowSection("dc", me) ? (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader component="div" inset>
            DC
          </ListSubheader>
          <ListItemButton onClick={() => setActiveContent("Incomings")}>
            <ListItemIcon>
              <IncomingsViewIcon />
            </ListItemIcon>
            <ListItemText primary="Incomings" />
          </ListItemButton>
          <ListItemButton onClick={() => setActiveContent("Def plan")}>
            <ListItemIcon>
              <DefPlanIcon />
            </ListItemIcon>
            <ListItemText primary="Def plan" />
          </ListItemButton>
          {/* <ListItemButton disabled>
            <ListItemIcon>
              <DefCommandsIcon />
            </ListItemIcon>
            <ListItemText primary="Def commands" />
          </ListItemButton>
          <ListItemButton disabled>
            <ListItemIcon>
              <ScoutCommandsIcon />
            </ListItemIcon>
            <ListItemText primary="Scouts" />
          </ListItemButton> */}
        </>
      ) : null}
      {shouldShowSection("oc", me) ? (
        <>
          <Divider sx={{ my: 1 }} />
          <ListSubheader component="div" inset>
            OC
          </ListSubheader>
          <ListItemButton onClick={() => setActiveContent("Hammers")}>
            <ListItemIcon>
              <SportsMmaIcon />
            </ListItemIcon>
            <ListItemText primary="Hammers" />
          </ListItemButton>
          <ListItemButton onClick={() => setActiveContent("Targets")}>
            <ListItemIcon>
              <TroubleshootIcon />
            </ListItemIcon>
            <ListItemText primary="Targets" />
          </ListItemButton>
          {/* <ListItemButton disabled>
            <ListItemIcon>
              <MonitorHeartIcon />
            </ListItemIcon>
            <ListItemText primary="Ops manager" />
          </ListItemButton> */}
        </>
      ) : null}
    </List>
  )
}
