import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { fetchData } from "../../util/api"
import { getAllTribeColumns } from "../../util/troopsOverview"
import TroopsContent from "../account/Troops"

export default function TroopsOverviewContent({ me, settings }) {
  const [villages, setVillages] = useState([])
  const [savedAccountTroops, setSavedAccountTroops] = useState({})
  const [rows, setRows] = useState([])

  const [allianceSelection] = useState(
    JSON.parse(window.localStorage.getItem("allianceSelection") || "{}")
  )

  const [focusedTravianAccount, setFocusedTravianAccount] = useState(undefined)

  useEffect(() => {
    fetchData("/troops/troops", (troopsData) => {
      setVillages(troopsData)
      const accountTroops = {}
      for (const troops of troopsData) {
        if (!accountTroops[troops.playerName]) {
          accountTroops[troops.playerName] = {
            id: troops.travianAccountId,
            playerName: troops.playerName,
            tribe: troops.tribe,
            accountType: troops.accountType,
            allyName: troops.allyName,
            troop1: 0,
            troop2: 0,
            troop3: 0,
            troop4: 0,
            troop5: 0,
            troop6: 0,
            troop7: 0,
            troop8: 0,
            troop9: 0,
            static: 0,
            updatedAt: troops.updatedAt || new Date(0).toISOString()
          }
        }
        accountTroops[troops.playerName].troop1 += troops.troop1
        accountTroops[troops.playerName].troop2 += troops.troop2
        accountTroops[troops.playerName].troop3 += troops.troop3
        accountTroops[troops.playerName].troop4 += troops.troop4
        accountTroops[troops.playerName].troop5 += troops.troop5
        accountTroops[troops.playerName].troop6 += troops.troop6
        accountTroops[troops.playerName].troop7 += troops.troop7
        accountTroops[troops.playerName].troop8 += troops.troop8
        accountTroops[troops.playerName].troop9 += troops.troop9
        accountTroops[troops.playerName].static += troops.static
        if (
          new Date(troops.updatedAt).getTime() >
          new Date(accountTroops[troops.playerName].updatedAt).getTime()
        ) {
          accountTroops[troops.playerName].updatedAt = troops.updatedAt
        }
      }
      // console.log(accountTroops)
      setSavedAccountTroops(accountTroops)
      setRows(
        Object.values(accountTroops).filter(
          (troops) => allianceSelection[troops.allyName] !== false
        )
      )
    })
  }, [allianceSelection])

  return focusedTravianAccount ? (
    <TroopsContent
      me={me}
      settings={settings}
      editable={false}
      presetRows={villages.filter(
        (village) => village.travianAccountId === focusedTravianAccount.id
      )}
      focusedTravianAccount={focusedTravianAccount}
      updatedAt={savedAccountTroops[focusedTravianAccount.playerName].updatedAt}
      returnToReferrer={() => setFocusedTravianAccount(undefined)}
    />
  ) : (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            dense
            height={800}
            columns={getAllTribeColumns({
              renderCell: ({ row }) => (
                <span style={{ cursor: "pointer", height: "100%" }}>
                  <ArrowForwardIcon onClick={() => setFocusedTravianAccount(row)} />
                </span>
              )
            })}
            rows={rows}
            confirm={false}
            sortModel={[{ field: "crop", sort: "desc" }]}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
