import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { Button, Input, Paper } from "@mui/material"
import { sendData } from "../../util/api"
import { parseIncomingsFromSourceCode } from "../../util/incomings"
import { useState } from "react"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

export default function ReportIncomingsContent({ me, settings }) {
  const options = [
    "--",
    "00:05:00",
    "00:10:00",
    "00:30:00",
    "01:00:00",
    "02:00:00",
    "03:00:00",
    "05:00:00",
    "08:00:00",
    "12:00:00",
    "24:00:00"
  ]
  const defaultOption = options[0]

  const [parseConfirmation, setParseConfirmation] = useState("")
  const [attacks, setAttacks] = useState([])
  const [villageType, setVillageType] = useState("")
  const [lastChecked, setLastChecked] = useState("")
  const [villageInputOpened, setVillageInputOpened] = useState(false)
  const [saveConfirmation, setSaveConfirmation] = useState("")

  const dropdownChange = async (e) => {
    if (e.value === "--") {
      setLastChecked(null)
    } else {
      setLastChecked(e.value)
    }
  }

  const handleRallyPointInput = async (e) => {
    setParseConfirmation("")
    setSaveConfirmation("")
    const incomings = parseIncomingsFromSourceCode(e.target.value)
    const incomingsCount = incomings
      .map((incoming) => incoming.waves)
      .reduce((a, b) => a + b, 0)
    const detectedAttacks = incomings.filter((incoming) => incoming.type === "attack")
    const attacksCount = detectedAttacks
      .map((incoming) => incoming.waves)
      .reduce((a, b) => a + b, 0)
    const raidsCount = incomings
      .filter((incoming) => incoming.type === "raid")
      .map((incoming) => incoming.waves)
      .reduce((a, b) => a + b, 0)
    if (incomingsCount > 0) {
      setParseConfirmation(
        `Parsed ${incomingsCount} hostile incoming${
          incomingsCount !== 1 ? "s" : ""
        } (${attacksCount} attack${attacksCount !== 1 ? "s" : ""}, ${raidsCount} raid${
          raidsCount !== 1 ? "s" : ""
        })`
      )
      if (attacksCount > 0) {
        try {
          setAttacks(detectedAttacks)
          setVillageInputOpened(true)
        } catch (e) {
          setSaveConfirmation(`Error saving attacks. Please contact your admin.`)
        }
      } else {
        setSaveConfirmation("Will not save raids.")
      }
    } else {
      setParseConfirmation("No hostile incomings found.")
    }
  }

  return (
    <Container maxWidth="" sx={{ mt: 12, mb: 12, ml: 12, mr: 12 }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={12} lg={12} xl={12} spacing={6}>
          <Paper
            sx={{
              p: 6,
              display: "flex",
              flexDirection: "column"
            }}
            variant="outlined"
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              Paste rally point source code to update village incomings:
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mb={4}>
              <Input
                style={{
                  backgroundColor: "white",
                  width: "300px",
                  height: "90px",
                  overflow: "hidden",
                  verticalAlign: "top",
                  fontSize: "0.5em"
                }}
                aria-label="Paste rally point source code"
                multiline
                onFocus={(e) => {
                  e.target.value = ""
                }}
                onChange={handleRallyPointInput}
              />
            </Grid>

            {villageInputOpened && (
              <Grid item container alignItems="center" justifyContent="center">
                What type of village is under attack?
                <Grid id="villageTypeInput" item xs={12} md={12} lg={12} xl={12} mb={4}>
                  <Input
                    required
                    style={{
                      backgroundColor: "white",
                      height: "80px",
                      width: "100%",
                      overflow: "hidden",
                      verticalAlign: "top",
                      color: "black"
                    }}
                    aria-label="What type of village is it?"
                    multiline
                    value={villageType}
                    onChange={(e) => setVillageType(e.target.value)}
                  />
                </Grid>
                <Grid id="villageTypeInput" item xs={4} md={4} lg={4} xl={4} mb={4}>
                  Last checked within
                  <Dropdown
                    options={options}
                    onChange={dropdownChange}
                    value={defaultOption}
                    placeholder="Last checked within"
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} md={12} lg={12} xl={12} mb={4}>
              {parseConfirmation}
              <br />
              {saveConfirmation}
            </Grid>

            <Button
              disabled={!villageType}
              variant="outlined"
              onClick={async () => {
                attacks.forEach((a) => (a.notes = villageType))
                attacks.forEach((a) => (a.lastChecked = lastChecked))
                await sendData(`/v2/incomings`, "POST", attacks).then((result) => {
                  const newSavedAttacksCount = result
                    .map((incoming) => incoming.waves)
                    .reduce((a, b) => a + b, 0)
                  setSaveConfirmation(
                    `Added ${newSavedAttacksCount} new attack${
                      newSavedAttacksCount !== 1 ? "s" : ""
                    }.`
                  )
                })
                setAttacks([])
                setVillageType("")
              }}
            >
              Confirm
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
