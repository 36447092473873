import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { sendData } from "../util/api"

export function TravianAccountSelection({
  me,
  activeTravianAccount,
  setActiveTravianAccount
}) {
  const switchTravianAccount = async (travianAccount) => {
    await sendData("/me/select-active-travian-account", "PUT", travianAccount, {
      useCache: true
    })
    setActiveTravianAccount(travianAccount)
  }
  return (
    <FormControl style={{ minWidth: 200, marginLeft: 10, marginTop: 10 }}>
      <InputLabel id="active-travian-account-select-label">Account</InputLabel>
      <Select
        labelId="active-travian-account-select-label"
        id="active-travian-account-select"
        value={
          (me.travianAccounts || []).find(
            (travianAccount) => travianAccount.id === activeTravianAccount?.id
          ) || ""
        }
        label="Account"
        onChange={(e) => switchTravianAccount(e.target.value)}
      >
        {(me.travianAccounts || []).map((travianAccount) => (
          <MenuItem key={travianAccount.id} value={travianAccount}>
            {travianAccount.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
