import { testJSONFor } from "./testData"

export const fetchData = async (url, setter, options) => {
  let value
  if (options?.useCache) {
    const cachedResponse = window.localStorage.getItem(url)
    if (cachedResponse) {
      value = JSON.parse(cachedResponse)
      if (value?.content && value.expiresAt) {
        if (Date.now() < new Date(value.expiresAt).getTime()) {
          console.log("using cached data for", url)
          value = value.content
        } else {
          value = undefined
          window.localStorage.removeItem(url)
        }
      }
    }
  }
  if (!value) {
    if (process.env.NODE_ENV === "development") {
      await new Promise((resolve) => setTimeout(resolve, 2000))
      value = JSON.parse(JSON.stringify(testJSONFor(url)))
    } else {
      const response = await fetch(url, { redirect: "manual" })
      if (response?.status === 401) {
        window.location.href = "/login"
      } else if (response.status > 399) {
        window.location.href = `/app?error=${response.status}`
      }
      if (options?.blob) {
        const blobValue = await response.blob()
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(blobValue)
        a.download = "targets.txt"
        a.click()
        return
      }
      value = await response.json()
      if (options?.useCache && value) {
        window.localStorage.setItem(
          url,
          JSON.stringify({ content: value, expiresAt: Date.now() + 12 * 60 * 60 * 1000 })
        )
      }
    }
  }
  setter(value)
}

export const sendData = async (url, method, data, options) =>
  new Promise(async (resolve, reject) => {
    if (process.env.NODE_ENV === "development") {
      console.log("sending", data)
      setTimeout(() => resolve(data), 200)
    } else {
      const body = JSON.stringify(data)
      if (options?.useCache) {
        window.localStorage.setItem(url, body)
      }
      const response = await fetch(url, {
        method,
        headers: options?.headers || {
          "Content-Type": "application/json"
        },
        body,
        redirect: "manual"
      })
      if (response?.status === 401) {
        window.location.href = "/login"
      }
      const resBody = await response.text()
      if (options?.useCache && resBody) {
        window.localStorage.setItem(url, resBody)
      }
      const resData = resBody ? JSON.parse(resBody) : {}
      if (response.ok) {
        resolve(resData)
      } else {
        reject(resData)
      }
    }
  })

export const deleteData = async (url, options) =>
  new Promise(async (resolve, reject) => {
    if (process.env.NODE_ENV === "development") {
      console.log("deleting", url)
      setTimeout(() => resolve(), 200)
    } else {
      if (options?.useCache) {
        window.localStorage.removeItem(url)
      }
      const response = await fetch(url, {
        method: "DELETE",
        redirect: "manual"
      })
      if (response?.status === 401) {
        window.location.href = "/login"
      }
      const resBody = await response.text()
      const resData = resBody ? JSON.parse(resBody) : {}
      if (response.ok) {
        resolve(resData)
      } else {
        reject(resData)
      }
    }
  })
