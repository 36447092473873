import { getTravelTime } from "./time"
import { getConsumption } from "./troops"

export const assembleSenderVillage = ({ village, unitSpeed }) => {
  const { xCoord, yCoord, tournamentSquare } = village
  return {
    xCoord,
    yCoord,
    unitSpeed,
    arteSpeed: 1,
    tournamentSquare: tournamentSquare || 0
  }
}

export const extractDefTroopBatches = ({ target, troopsVillage, settings }) => {
  let troopBatches = []

  switch (troopsVillage.tribe) {
    case 1:
      troopBatches = [
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 6 }),
            settings
          ),
          crop: getConsumption({ troop1: troopsVillage.troop1 }, "Roman") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 5 }),
            settings
          ),
          crop: getConsumption({ troop2: troopsVillage.troop2 }, "Roman") || 0
        }
      ]
      break

    case 2:
      troopBatches = [
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 7 }),
            settings
          ),
          crop: getConsumption({ troop2: troopsVillage.troop2 }, "Teuton") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 10 }),
            settings
          ),
          crop: getConsumption({ troop5: troopsVillage.troop5 }, "Teuton") || 0
        }
      ]
      break

    case 3:
      troopBatches = [
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 7 }),
            settings
          ),
          crop: getConsumption({ troop1: troopsVillage.troop1 }, "Gaul") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 16 }),
            settings
          ),
          crop: getConsumption({ troop5: troopsVillage.troop5 }, "Gaul") || 0
        }
      ]
      break

    case 5:
      troopBatches = [
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 6 }),
            settings
          ),
          crop: getConsumption({ troop1: troopsVillage.troop1 }, "Hun") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 15 }),
            settings
          ),
          crop: getConsumption({ troop5: troopsVillage.troop5 }, "Hun") || 0
        }
      ]
      break

    case 6:
      troopBatches = [
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 7 }),
            settings
          ),
          crop: getConsumption({ troop1: troopsVillage.troop1 }, "Egyptian") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 6 }),
            settings
          ),
          crop: getConsumption({ troop2: troopsVillage.troop2 }, "Egyptian") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 15 }),
            settings
          ),
          crop: getConsumption({ troop5: troopsVillage.troop5 }, "Egyptian") || 0
        },
        {
          timeToTarget: getTravelTime(
            target.defender,
            assembleSenderVillage({ village: troopsVillage, unitSpeed: 10 }),
            settings
          ),
          crop: getConsumption({ troop6: troopsVillage.troop6 }, "Egyptian") || 0
        }
      ]
      break

    default:
      break
  }

  let staticAmount = troopsVillage.static
  if (staticAmount) {
    for (let i = 0; i < troopBatches.length; i++) {
      const staticDiscountForTroopBatch = Math.min(staticAmount, troopBatches[i].crop)
      troopBatches[i].crop -= staticDiscountForTroopBatch
      staticAmount -= staticDiscountForTroopBatch
      if (staticAmount <= 0) {
        break
      }
    }
  }

  return troopBatches
}

export const assembleTroopBatches = ({ target, troops, settings }) => {
  const mappedData = []

  ;(troops || []).forEach((troopsVillage) => {
    mappedData.push(...extractDefTroopBatches({ target, troopsVillage, settings }))
  })

  return mappedData.sort((a, b) => a.timeToTarget - b.timeToTarget)
}
