import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { Button, Checkbox } from "@mui/material"
import { json2csv } from "json-2-csv"

import { useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { fetchData } from "../../util/api"
import { getAllTribeColumns } from "../../util/troopsOverview"

export default function HammersContent({ me, settings }) {
  const [rows, setRows] = useState([])

  const [allianceSelection] = useState(
    JSON.parse(window.localStorage.getItem("allianceSelection") || "{}")
  )

  const [csv, setCsv] = useState(undefined)
  const [csvUrl, setCsvUrl] = useState("")

  useEffect(() => {
    fetchData("/troops/troops", (troopsData) => {
      setRows(
        Object.values(troopsData).filter(
          (troops) => allianceSelection[troops.allyName] !== false
        )
      )
    })
  }, [allianceSelection])

  const getOffSizeString = (tribe, troops) => {
    console.log("getOffSizeString", tribe, troops)

    switch (tribe) {
      case 1:
        return `${troops.troop3}+${troops.troop5}+${troops.troop6}+${troops.troop7}+${troops.troop8}`
      case 2:
        return `${troops.troop1}+${troops.troop2}+${troops.troop6}+${troops.troop7}+${troops.troop8}`
      case 3:
        return `${troops.troop2}+${troops.troop4}+${troops.troop6}+${troops.troop7}+${troops.troop8}`
      case 6:
        return `${troops.troop3}+${troops.troop6}+${troops.troop7}+${troops.troop8}`
      case 7:
        return `${troops.troop1}+${troops.troop2}+${troops.troop4}+${troops.troop5}+${troops.troop6}+${troops.troop7}+${troops.troop8}`
      default:
        return ""
    }
  }

  const updateCsv = async () => {
    const convertable = []
    for (const hammer of rows.filter((row) => row.selected)) {
      convertable.push({ 1: hammer.playerName, 2: "", 3: "", 4: "" })
      convertable.push({ 1: getOffSizeString(hammer.tribe, hammer), 2: "", 3: "", 4: "" })
      convertable.push({ 1: "00.00.00", 2: "00.00.00", 3: "", 4: "" })
      convertable.push({
        1: hammer.xCoord,
        2: hammer.yCoord,
        3: "X",
        4: "Y"
      })
      convertable.push({
        1: `${hammer.tournamentSquare}`,
        2: 3,
        3: "TS",
        4: "SPD"
      })
    }
    const csvBlob = new Blob([await json2csv(convertable)], {
      type: "text/csv",
      prependHeader: false
    })
    setCsv(csvBlob)
    setCsvUrl(window.URL.createObjectURL(csvBlob))
  }

  function DownloadElement() {
    return csv ? (
      <a
        href={csvUrl}
        download={"hammers.csv"}
        style={{ float: "right", marginLeft: "auto", marginRight: "10px" }}
      >
        <Button sx={{ ml: "12px", mt: "20px", height: "44px" }} variant="outlined">
          Download CSV
        </Button>
      </a>
    ) : (
      <Button
        disabled
        style={{ float: "right", marginLeft: "auto", marginRight: "10px" }}
        sx={{ ml: "12px", mt: "20px", height: "44px" }}
        variant="outlined"
      >
        Download CSV
      </Button>
    )
  }

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            dense
            height={800}
            columns={getAllTribeColumns({
              renderCell: ({ row }) => (
                <span style={{ cursor: "pointer" }}>
                  <Checkbox
                    value={row.selected}
                    onChange={() => {
                      row.selected = !row.selected
                      updateCsv()
                    }}
                  />
                </span>
              ),
              coordinates: true,
              staticColumn: false
            })}
            rows={rows}
            confirm={false}
            sortModel={[{ field: "crop", sort: "desc" }]}
            ToolbarElement={DownloadElement}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
