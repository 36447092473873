import { inServerTime } from "../util/time"

export function StackedTime({ time, settings }) {
  return (
    <>
      <div style={{ textAlign: "left" }}>
        {inServerTime(new Date(time), settings).split(" ")[0]}
        <br />
        {inServerTime(new Date(time), settings).split(" ")[1]}
      </div>
    </>
  )
}
