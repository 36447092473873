import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { fetchData } from "../../util/api"
import { renderUpdatedAt } from "../../util/troopsOverview"
import { getTribeName } from "../../util/tribes"

export default function StatisticsOverviewContent({ me, settings }) {
  const [rows, setRows] = useState([])

  useEffect(() => {
    fetchData("/statistics/guild", (guildStatistics) => {
      setRows(
        guildStatistics.map((travianAccount) => {
          const accountLatestStatistics = travianAccount.statistics.sort(
            (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )[0]
          return accountLatestStatistics
            ? {
                ...travianAccount,
                ...accountLatestStatistics
              }
            : {
                ...travianAccount,
                updatedAt: new Date(0).toISOString()
              }
        })
      )
    })
  }, [])

  const columns = [
    {
      field: "name",
      headerName: "Account",
      width: 160,
      editable: false
    },
    {
      field: "tribe",
      headerName: "Tribe",
      width: 80,
      editable: false,
      valueFormatter: ({ value }) => getTribeName(value)
    },
    {
      field: "accountType",
      headerName: "Type",
      width: 75,
      editable: false
    },
    {
      field: "alliance",
      headerName: "Alliance",
      width: 90,
      editable: false
    },
    {
      field: "hourlyProd",
      headerName: "Prod/h",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => {
        const res = row.resourceRank?.production?.perDay
        return res ? (res.r1 + res.r2 + res.r3 + res.r4) / 24 : 0
      },
      valueFormatter: ({ value }) =>
        value < 1000 ? value : `${((value || 0) / 1000).toFixed(1)}k`
    },
    {
      field: "hourlyProdRank",
      headerName: "#",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => row.resourceRank?.rankDaily?.server || ""
    },
    {
      field: "totalRes",
      headerName: "Total res",
      width: 80,
      editable: false,
      valueGetter: ({ row }) => {
        const res = row.resourceRank
        return res ? res.positive.sum - res.negative.sum : ""
      },
      valueFormatter: ({ value }) =>
        value < 10000000
          ? `${((value || 0) / 1000).toFixed(1)}k`
          : `${((value || 0) / 1000000).toFixed(1)}M`
    },
    {
      field: "totalResRank",
      headerName: "#",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => row.resourceRank?.rank?.server || ""
    },
    {
      field: "dailyCp",
      headerName: "Daily cp",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => {
        return row.culturePointsRank
          ? row.culturePointsRank.rank?.perDay?.cpProduction
          : ""
      },
      valueFormatter: ({ value }) =>
        value < 1000 ? value : `${((value || 0) / 1000).toFixed(1)}k`
    },
    {
      field: "dailyCpRank",
      headerName: "#",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => row.culturePointsRank?.rank?.perDay?.serverRank || ""
    },
    {
      field: "totalCp",
      headerName: "Total cp",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => {
        return row.culturePointsRank
          ? row.culturePointsRank.rank?.soFar?.cpProduction
          : ""
      },
      valueFormatter: ({ value }) =>
        value < 1000 ? value : `${((value || 0) / 1000).toFixed(1)}k`
    },
    {
      field: "totalCpRank",
      headerName: "#",
      width: 75,
      editable: false,
      valueGetter: ({ row }) => row.culturePointsRank?.rank?.soFar?.serverRank || ""
    },
    {
      field: "defRank",
      headerName: "Def #",
      width: 80,
      editable: false,
      valueGetter: ({ row }) => row.villageStrength?.defenceRanks?.server || ""
    },

    {
      field: "offRank",
      headerName: "Off #",
      width: 120,
      editable: false,
      valueGetter: ({ row }) => {
        const villageRanks =
          row.villageStrength?.villages &&
          Object.values(row.villageStrength?.villages)
            .map((village) => village.offenceRanks.server)
            .filter((rank) => rank < 1000)
            .sort((a, b) => a - b)
        // console.log(villageRanks)
        return villageRanks ? `${villageRanks.join(", ")}` : ""
      }
    },
    {
      field: "serverAgeInDays",
      headerName: "Day",
      width: 70,
      editable: false
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      width: 100,
      editable: false,
      valueFormatter: ({ value }) =>
        typeof value === "string" && value?.length > 10 && value.substring(0, 10),
      renderCell: renderUpdatedAt
    }
  ]

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            dense
            height={800}
            columns={columns}
            rows={rows}
            confirm={false}
            sortModel={[{ field: "serverAgeInDays", sort: "desc" }]}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
