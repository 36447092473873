import React, { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { fetchData, sendData } from "../util/api"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}Mastertool {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#363D60"
    },
    secondary: {
      main: "#9A377C"
    }
  }
})

export default function GuildSelector() {
  const [guilds, setGuilds] = useState([])

  useEffect(() => {
    fetchData("/guild-selection/list", setGuilds)
  }, [])

  const [guild, setGuild] = useState("")

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Typography component="h2" variant="h2">
            Mastertool
          </Typography>
          <Typography component="p" variant="caption">
            Roster building | Trooptool | Statistics | Def planner | Discord integration
          </Typography>
          <Typography component="h5" variant="h5" style={{ marginTop: "20px" }}>
            Select server
          </Typography>
          <FormControl style={{ minWidth: "300px", marginTop: "20px" }}>
            <InputLabel id="guild-select-label">Server</InputLabel>
            <Select
              labelId="guild-select-label"
              id="guild-select"
              value={guild}
              label="Server"
              onChange={(e) => setGuild(e.target.value)}
            >
              {guilds.map((guild) => (
                <MenuItem key={guild.id} value={guild}>
                  {guild.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={async () => {
              await sendData("/guild-selection", "POST", { guildId: guild.id })
              window.location.href = "/login"
            }}
          >
            Start using Mastertool
          </Button>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
