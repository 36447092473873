import { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { inServerTime } from "../util/time"

export default function ServerTime({ settings = {} }) {
  const [serverTime, setServerTime] = useState("")

  useEffect(() => {
    const serverTimeInterval = setInterval(() => {
      setServerTime(inServerTime(new Date(), settings))
    }, 100)
    return () => clearInterval(serverTimeInterval)
  }, [settings])

  return (
    <Typography
      component="p"
      variant="caption"
      color="inherit"
      noWrap
      sx={{
        pr: "24px",
        width: 220,
        textAlign: "right",
        overflow: "visible"
      }}
    >
      Server time: {serverTime}
    </Typography>
  )
}
