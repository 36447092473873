import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Typography
} from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { memo, useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { fetchData, sendData } from "../../util/api"
import { formatTime, inServerTime } from "../../util/time"
import TravelGraph from "../../components/TravelGraph"
import { assembleTroopBatches } from "../../util/defPlan"
import DefCallControls from "../../components/DefCallControls"
import DeleteIcon from "@mui/icons-material/Delete"
import { v4 as uuidv4 } from "uuid"

export default function DefPlanContent({ me, settings }) {
  const [snackbar, setSnackbar] = useState(null)
  const handleCloseSnackbar = () => setSnackbar(null)
  const [rows, setRows] = useState([])
  const [targets, setTargets] = useState([])
  const [target, setTarget] = useState("")
  const [defCalls, setDefCalls] = useState([])
  const [openDefCalls, setOpenDefCalls] = useState([])
  const [loadingTarget, setLoadingTarget] = useState(true)
  const [loadingTravianAccounts, setLoadingTravianAccounts] = useState(true)
  const [loadingDefCalls, setLoadingDefCalls] = useState(true)
  const [loadingOpenDefCalls, setLoadingOpenDefCalls] = useState(true)

  const [allianceSelection] = useState(
    JSON.parse(window.localStorage.getItem("allianceSelection") || "{}")
  )

  const getCropInDefCalls = (travianAccountId) => {
    return defCalls
      .filter((defCall) => defCall.travianAccountId === travianAccountId)
      .map((defCall) => defCall.amount)
      .reduce((prev, curr) => prev + curr, 0)
  }

  useEffect(() => {
    fetchData(`/v2/incomings/active`, (incomings) => {
      setTargets(
        (incomings || []).filter(
          (incoming) =>
            incoming.call === "Def" &&
            allianceSelection[incoming.defender.allyName] !== false
        )
      )
      const savedTargetId = window.localStorage.getItem("selectedTarget")
      const savedTarget = incomings.find((incoming) => incoming.id === savedTargetId)
      if (savedTarget) {
        setTarget(savedTarget)
      }
      setLoadingTarget(false)
    })
    fetchData("/travian-accounts", async (members) => {
      const membersTroops = {}
      const troopFetches = (members || [])
        .filter(
          (member) =>
            (member.accountType === "Def" ||
              member.accountType === "Hybrid" ||
              member.accountType === "Tech") &&
            allianceSelection[member.alliance] !== false
        )
        .map((member) =>
          fetchData(
            `/troops/${member.id}/troops`,
            (troops) => (membersTroops[member.id] = troops)
          )
        )
      await Promise.all(troopFetches)
      setRows(
        members
          .filter(
            (member) =>
              (member.accountType === "Def" ||
                member.accountType === "Hybrid" ||
                member.accountType === "Tech") &&
              allianceSelection[member.alliance] !== false
          )
          .map((member) => {
            return {
              ...member,
              troops: membersTroops[member.id]
            }
          })
      )
      setLoadingTravianAccounts(false)
    })
    fetchData("/def-calls/active", (defCalls) => {
      setDefCalls(defCalls)
      setLoadingDefCalls(false)
    })
    fetchData("/open-def-calls/active", (openDefCalls) => {
      setOpenDefCalls(openDefCalls)
      setLoadingOpenDefCalls(false)
    })
  }, [allianceSelection])

  const addDefCall = async (defCall) => {
    setDefCalls([...defCalls, defCall])
    try {
      await sendData(`/def-calls`, "POST", defCall)
      setSnackbar({ children: "Saved successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const removeDefCall = async (defCallId) => {
    setDefCalls(defCalls.filter((defCall) => defCall.id !== defCallId))
    try {
      await sendData(`/def-calls/${defCallId}`, "DELETE")
      setSnackbar({ children: "Saved successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const postDefCall = async (incomingId) => {
    try {
      const defCallPosted = await sendData(
        `/def-calls/${incomingId}/notification`,
        "POST"
      )
      setTarget({ ...target, defCallPosted })
      setSnackbar({ children: "Posted successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const addOpenDefCall = async (openDefCall) => {
    setOpenDefCalls([...openDefCalls, openDefCall])
    try {
      await sendData(`/open-def-calls`, "POST", openDefCall)
      setSnackbar({ children: "Saved successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const removeOpenDefCall = async (openDefCallId) => {
    setOpenDefCalls(
      openDefCalls.filter((openDefCall) => openDefCall.id !== openDefCallId)
    )
    try {
      await sendData(`/open-def-calls/${openDefCallId}`, "DELETE")
      setSnackbar({ children: "Saved successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const postOpenDefCall = async (incomingId) => {
    try {
      const defCallPosted = await sendData(
        `/open-def-calls/${incomingId}/notification`,
        "POST"
      )
      setTarget({ ...target, defCallPosted })
      setSnackbar({ children: "Posted successfully", severity: "success" })
    } catch (e) {
      console.error(e)
      setSnackbar({
        children: `${e}`,
        severity: "error"
      })
    }
  }

  const ToolbarControls = memo(() => {
    const [targetNotes, setTargetNotes] = useState("")
    const [openCallAmount, setOpenCallAmount] = useState(0)

    const setAndSaveTarget = (target) => {
      window.localStorage.setItem("selectedTarget", target.id)
      setTarget(target)
      setTargetNotes(target.defCallNotes || "")
    }

    useEffect(() => {
      setTargetNotes(target.defCallNotes || "")
    }, [setTargetNotes])

    return (
      <>
        <div style={{ width: "100%", display: "flex" }}>
          <FormControl
            style={{ width: "auto", minWidth: 200, marginLeft: 10, marginTop: 10 }}
          >
            <InputLabel id="active-target-select-label">Target</InputLabel>
            <Select
              labelId="active-target-select-label"
              id="active-target-select"
              value={target}
              label="Target"
              onChange={(e) => setAndSaveTarget(e.target.value)}
            >
              {targets.map((target) => (
                <MenuItem key={target.id} value={target}>
                  {target.defender.playerName}, {target.defender.villageName}{" "}
                  {`(${target.defender.xCoord}|${target.defender.yCoord})`}{" "}
                  {inServerTime(new Date(target.arrival), settings)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!target && loadingTarget ? <CircularProgress /> : ""}
          {!!target ? (
            <>
              <FormControl
                style={{ width: "auto", minWidth: 200, marginLeft: 10, marginTop: 10 }}
              >
                <InputLabel id="target-notes-label">Notes</InputLabel>
                <Input
                  style={{
                    backgroundColor: "#444444",
                    height: "44px",
                    width: "200px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    paddingLeft: "8px",
                    overflow: "hidden"
                  }}
                  aria-label="Notes"
                  value={targetNotes}
                  onChange={(e) => setTargetNotes(e.target.value)}
                />
              </FormControl>
              <Button
                sx={{ ml: "12px", mt: "20px", height: "44px" }}
                variant="outlined"
                onClick={async () => {
                  try {
                    target.defCallNotes = targetNotes
                    await sendData(`/v2/incomings/${target.id}`, "PATCH", target)
                    setSnackbar({ children: "Saved successfully", severity: "success" })
                  } catch (e) {
                    console.error(e)
                    setSnackbar({
                      children: `${e}`,
                      severity: "error"
                    })
                  }
                }}
              >
                Save
              </Button>
              <Paper sx={{ ml: "10px", mt: "10px", p: "10px" }}>
                <Typography component="p" variant="subtitle1">
                  Available def:{" "}
                  {(
                    rows
                      .map((row) =>
                        assembleTroopBatches({
                          target,
                          troops: row.troops,
                          settings
                        })
                          .map((troopBatch) => +troopBatch.crop)
                          .reduce((sum, crop) => sum + crop, 0)
                      )
                      .reduce((prev, curr) => prev + curr, 0) /
                      1000 -
                    defCalls
                      .map((defCall) => +defCall.amount)
                      .reduce((prev, curr) => {
                        return prev + curr
                      }, 0) /
                      1000
                  ).toFixed(0)}
                  k/
                  {(
                    rows
                      .map((row) => {
                        const ret = assembleTroopBatches({
                          target,
                          troops: row.troops,
                          settings
                        })
                          .map((troopBatch) => +troopBatch.crop)
                          .reduce((sum, crop) => sum + crop, 0)
                        console.log(row.name, ret)
                        return ret
                      })
                      .reduce((prev, curr) => prev + curr, 0) / 1000
                  ).toFixed(0)}
                  k
                </Typography>
              </Paper>
            </>
          ) : (
            ""
          )}
          {!rows.length && loadingTravianAccounts ? (
            <CircularProgress
              style={{ float: "right", marginLeft: "auto", marginRight: "10px" }}
            />
          ) : (
            ""
          )}
        </div>
        {!!target && (
          <>
            <Paper variant="outlined" sx={{ ml: "10px", p: "10px" }}>
              <div style={{ width: "100%", display: "flex" }}>
                <Typography component="p" variant="h6">
                  Call
                </Typography>
                <Typography
                  component="p"
                  variant="h6"
                  style={{ float: "right", marginLeft: "auto", marginRight: "10px" }}
                >
                  {(
                    defCalls
                      .filter((defCall) => defCall?.incomingId === target.id)
                      .map((defCall) => +defCall.amount)
                      .reduce((prev, curr) => {
                        return prev + curr
                      }, 0) / 1000
                  ).toFixed(0)}
                  k
                </Typography>
              </div>
              {loadingDefCalls ? <CircularProgress /> : ""}
              {defCalls.filter((defCall) => defCall?.incomingId === target.id).length ? (
                defCalls
                  .filter((defCall) => defCall?.incomingId === target.id)
                  .map((defCall) => {
                    return (
                      <div
                        key={defCall.id}
                        style={{
                          width: "100%",
                          display: "flex",
                          marginLeft: "2px",
                          marginTop: "2px",
                          verticalAlign: "bottom"
                        }}
                      >
                        <IconButton
                          aria-label="remove-defender"
                          size="small"
                          sx={{ padding: "1px 4px" }}
                          onClick={() => {
                            removeDefCall(defCall?.id)
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <span
                          style={{
                            width: "120px",
                            textAlign: "left"
                          }}
                        >
                          {defCall?.travianAccount?.name}
                        </span>{" "}
                        <i
                          style={{
                            width: "50px",
                            textAlign: "left"
                          }}
                        >
                          {defCall?.travianAccount?.tribe}
                        </i>{" "}
                        <b
                          style={{
                            width: "80px",
                            textAlign: "right",
                            marginRight: "14px"
                          }}
                        >
                          {defCall?.amount}
                        </b>
                        {/* {" "}
                      {defCall?.notes} */}
                      </div>
                    )
                  })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    marginLeft: 14,
                    marginTop: 0
                  }}
                >
                  No defenders selected.
                </div>
              )}

              <Button
                variant="outlined"
                fullWidth
                style={{ float: "right", marginTop: 12, marginBottom: 6 }}
                onClick={() => {
                  postDefCall(target.id)
                }}
              >
                Post
              </Button>
              {target.defCallPosted &&
                `Posted at ${inServerTime(target.defCallPosted, settings)}`}
            </Paper>

            <Paper variant="outlined" sx={{ ml: "10px", p: "10px" }}>
              <div style={{ width: "100%", display: "flex" }}>
                <Typography component="p" variant="h6">
                  Open call
                </Typography>
              </div>

              {loadingOpenDefCalls ? <CircularProgress /> : ""}
              {openDefCalls.filter((openDefCall) => openDefCall?.incomingId === target.id)
                .length ? (
                openDefCalls
                  .filter((openDefCall) => openDefCall?.incomingId === target.id)
                  .map((openDefCall) => {
                    return (
                      <div
                        key={openDefCall.id}
                        style={{
                          width: "100%",
                          display: "flex",
                          marginLeft: "2px",
                          marginTop: "2px",
                          verticalAlign: "bottom"
                        }}
                      >
                        <IconButton
                          aria-label="remove-defender"
                          size="small"
                          sx={{ padding: "1px 4px" }}
                          onClick={() => {
                            removeOpenDefCall(openDefCall?.id)
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                        <b
                          style={{
                            width: "80px",
                            textAlign: "right",
                            marginRight: "14px"
                          }}
                        >
                          {openDefCall?.amount}
                        </b>
                        {/* {" "}
                        {openDefCall?.notes} */}
                      </div>
                    )
                  })
              ) : (
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginLeft: 14,
                      marginTop: 0
                    }}
                  >
                    No open def call.
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 0
                    }}
                  >
                    <FormControl style={{ marginTop: 6 }}>
                      <InputLabel id="open-def-call-amount-label">Amount</InputLabel>
                      <Input
                        style={{ backgroundColor: "#444444", margin: 10, padding: 8 }}
                        type="number"
                        aria-label="Amount"
                        value={openCallAmount}
                        onChange={(e) => setOpenCallAmount(e.target.value)}
                      />
                    </FormControl>
                    <Button
                      variant="outlined"
                      disabled={openCallAmount <= 0}
                      onClick={() => {
                        addOpenDefCall({
                          id: uuidv4(),
                          incomingId: target.id,
                          amount: openCallAmount,
                          arrival: target.arrival
                        })
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}

              <Button
                variant="outlined"
                fullWidth
                style={{ float: "right", marginTop: 12, marginBottom: 6 }}
                onClick={() => {
                  postOpenDefCall(target.id)
                }}
              >
                Post
              </Button>
              {target.defCallPosted &&
                `Posted at ${inServerTime(target.defCallPosted, settings)}`}
            </Paper>
          </>
        )}
      </>
    )
  })

  const timeUntilHit = (new Date(target.arrival).getTime() - new Date().getTime()) / 1000

  const getTimeUntil90Percent = ({ row }) => {
    const troopBatches = assembleTroopBatches({
      target,
      troops: row.troops,
      settings
    })
    let totalCrop = 0
    for (const batch of troopBatches) {
      totalCrop += batch.crop
    }

    let timeUntil90Percent = 0
    let cumulativeCrop = 0
    for (const batch of troopBatches.sort((a, b) => a.timeUntilHit - b.timeUntilHit)) {
      cumulativeCrop += batch.crop
      if (cumulativeCrop / totalCrop > 0.9) {
        timeUntil90Percent = batch.timeToTarget
        break
      }
    }
    return timeUntil90Percent
  }

  const columns = [
    {
      field: "name",
      headerName: "Account",
      width: 180,
      editable: false
    },
    {
      field: "alliance",
      headerName: "Alliance",
      width: 80,
      editable: false
    },
    {
      field: "tribe",
      headerName: "Tribe",
      width: 70,
      editable: false
    },
    {
      field: "crop",
      headerName: "Total def",
      width: 110,
      editable: false,
      valueGetter: (params) =>
        assembleTroopBatches({ target, troops: params.row.troops, settings }).reduce(
          (sum, troopBatch) => sum + troopBatch.crop,
          0
        ) / 1000,
      valueFormatter: (params) => `${Math.floor(params.value)}k`
    },
    {
      field: "cropOnTime",
      headerName: "Def on time",
      width: 110,
      editable: false,
      valueGetter: (params) =>
        (assembleTroopBatches({ target, troops: params.row.troops, settings })
          .filter((troopBatch) => troopBatch.timeToTarget < timeUntilHit)
          .reduce((sum, troopBatch) => sum + troopBatch.crop, 0) -
          getCropInDefCalls(params.row.id)) /
        1000,
      valueFormatter: (params) => `${Math.floor(params.value)}k`
    },
    {
      field: "timeUntil90Percent",
      headerName: "90% @",
      width: 80,
      editable: false,
      valueGetter: getTimeUntil90Percent,
      valueFormatter: ({ value }) => formatTime(value)
    },
    {
      field: "traveltimeGraph1",
      headerName: "Travel graph",
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <TravelGraph
          timeUntilHit={timeUntilHit}
          timeUntil90Percent={getTimeUntil90Percent({ row })}
          troopBatches={assembleTroopBatches({ target, troops: row.troops, settings })}
        />
      )
    },
    {
      field: "makeDefCall",
      headerName: "Def call",
      width: 300,
      editable: false,
      renderCell: ({ row }) => (
        <DefCallControls
          settings={settings}
          target={target}
          row={row}
          getCropInDefCalls={getCropInDefCalls}
          timeUntilHit={timeUntilHit}
          addDefCall={addDefCall}
        />
      )
    }
  ]

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            height={800}
            columns={columns}
            rows={rows}
            rowHeight={100}
            ToolbarElement={ToolbarControls}
          />
          {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
// TODO: another page which lists all active def calls with delete buttons.
