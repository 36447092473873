import { getTribeName } from "./tribes"
import { getConsumption } from "./troops"

const renderUpdatedAt = ({ row }) => {
  const value = row.updatedAt
  const date = typeof value === "string" && value?.length >= 10 && value.substring(0, 10)
  let color = "red"
  if (new Date(row.updatedAt) > Date.now() - 7 * 24 * 60 * 60 * 1000) {
    color = "yellow"
  }
  if (new Date(row.updatedAt) > Date.now() - 3 * 24 * 60 * 60 * 1000) {
    color = "green"
  }
  return (
    <>
      <span style={{ color }}>{date}</span>
    </>
  )
}

const getAllTribeColumns = ({ renderCell, coordinates = false, staticColumn = true }) => {
  const columns = [
    {
      field: "select",
      headerName: "",
      width: 30,
      editable: false,
      renderCell
    },
    {
      field: "playerName",
      headerName: "Account",
      width: 160,
      editable: false
    },
    coordinates
      ? {
          field: "coords",
          headerName: "Coords",
          width: 90,
          editable: false,
          valueGetter: (params) => `(${params.row.xCoord}|${params.row.yCoord})`
        }
      : undefined,
    {
      field: "tribe",
      headerName: "Tribe",
      width: 80,
      editable: false,
      valueFormatter: ({ value }) => getTribeName(value)
    },
    {
      field: "accountType",
      headerName: "Type",
      width: 75,
      editable: false
    },
    {
      field: "allyName",
      headerName: "Alliance",
      width: 70,
      editable: false
    },
    {
      field: "troop1",
      headerName: "Troop1",
      width: 70,
      editable: false
    },
    {
      field: "troop2",
      headerName: "Troop2",
      width: 70,
      editable: false
    },
    {
      field: "troop3",
      headerName: "Troop3",
      width: 70,
      editable: false
    },
    {
      field: "troop4",
      headerName: "Troop4",
      width: 70,
      editable: false
    },
    {
      field: "troop5",
      headerName: "Troop5",
      width: 70,
      editable: false
    },
    {
      field: "troop6",
      headerName: "Troop6",
      width: 70,
      editable: false
    },
    {
      field: "troop7",
      headerName: "Troop7",
      width: 70,
      editable: false
    },
    {
      field: "troop8",
      headerName: "Troop8",
      width: 70,
      editable: false
    },
    {
      field: "troop9",
      headerName: "Troop9",
      width: 70,
      editable: false
    },
    {
      field: "crop",
      headerName: "Crop",
      width: 80,
      valueGetter: ({ row }) => getConsumption(row, getTribeName(row.tribe))
    },
    staticColumn
      ? { field: "static", headerName: "Static", width: 70, editable: false }
      : undefined,
    {
      field: "updatedAt",
      headerName: "Updated",
      width: 100,
      editable: false,
      valueFormatter: ({ value }) =>
        typeof value === "string" && value?.length > 10 && value.substring(0, 10),
      renderCell: renderUpdatedAt
    }
  ]
  return columns.filter(Boolean)
}

export { getAllTribeColumns, renderUpdatedAt }
