import { Checkbox, FormControlLabel, FormGroup, Paper } from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import MasterDataGrid from "../components/MasterDataGrid"
import Title from "../components/Title"
import { fetchData, sendData } from "../util/api"

const columns = [
  { field: "name", headerName: "Setting", width: 180, editable: false },
  { field: "value", headerName: "Value", flex: 1, editable: true }
]

const mutateRow = async (newRow) => {
  return await sendData(`/settings/${newRow.id}`, "PATCH", newRow)
}

export default function SettingsContent({ me, settings }) {
  const [rows] = useState(Object.values(settings))
  const [alliances, setAlliances] = useState([])
  const [allianceSelection, setAllianceSelection] = useState(
    JSON.parse(window.localStorage.getItem("allianceSelection") || "{}")
  )

  useEffect(() => {
    fetchData("/alliances", (allys) => {
      setAlliances(allys)
    })
  }, [])

  const toggleAllianceSelection = (alliance) => {
    const currentState = allianceSelection[alliance] !== false
    const newAllianceSelection = { ...allianceSelection, [alliance]: !currentState }
    window.localStorage.setItem("allianceSelection", JSON.stringify(newAllianceSelection))
    setAllianceSelection(newAllianceSelection)
  }

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9} xl={9}>
          <MasterDataGrid
            height={600}
            columns={columns}
            rows={rows}
            mutateRow={mutateRow}
            sortModel={[{ field: "name", sort: "asc" }]}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3} xl={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240
            }}
          >
            <Title>Info</Title>
            Insert access role names separated by comma and space, e.g.: Main, Tech, DC
          </Paper>
          <Paper
            sx={{
              p: 2,
              mt: 2,
              display: "flex",
              flexDirection: "column",
              height: 240
            }}
          >
            <Title>Alliances</Title>
            Select alliances to view in Troops overview, Incomings and Def plan.
            <br />
            <FormGroup>
              {alliances.map((alliance) => {
                return (
                  <FormControlLabel
                    key={alliance}
                    control={
                      <Checkbox
                        checked={allianceSelection?.[alliance] !== false}
                        onChange={() => {
                          toggleAllianceSelection(alliance)
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={alliance}
                  />
                )
              })}
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
