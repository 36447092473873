import React from "react"
import { useSearchParams } from "react-router-dom"
import "./App.css"
import AppFrame from "./pages/AppFrame"
import GuildSelector from "./pages/GuildSelector"
import ErrorPage from "./pages/ErrorPage"

function App() {
  let Component = AppFrame
  const [searchParams] = useSearchParams()
  const guildSelectionPageNumber = searchParams.get("guild-selection")
  if (guildSelectionPageNumber) {
    Component = GuildSelector
  }
  const errorCode = searchParams.get("error")
  if (errorCode) {
    Component = ErrorPage
  }
  return (
    <div className="App">
      <Component errorCode={errorCode} />
    </div>
  )
}

export default App
