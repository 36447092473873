import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { fetchData, sendData } from "../../util/api"
import { TravianAccountSelection } from "../../components/TravianAccountSelection"
import {
  getColumnsForTribe,
  parseTroopsFromHTML,
  parseTroopsFromOverview
} from "../../util/troops"
import { Input, Typography } from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { getTribeName } from "../../util/tribes"
import { renderUpdatedAt } from "../../util/troopsOverview"

export default function TroopsContent({
  me,
  settings,
  editable = true,
  presetRows,
  focusedTravianAccount,
  updatedAt,
  returnToReferrer
}) {
  const [snackbar, setSnackbar] = useState(null)
  const handleCloseSnackbar = () => setSnackbar(null)
  const [rows, setRows] = useState([])
  const [activeTravianAccount, setActiveTravianAccount] = useState(
    me.travianAccounts?.find(
      (travianAccount) => travianAccount.id === me.activeTravianAccountId
    )
  )

  useEffect(() => {
    if (activeTravianAccount) {
      fetchData(`/troops/${activeTravianAccount.id}/troops`, setRows)
    }
  }, [activeTravianAccount])

  const mutateRow = async (newRow) => {
    if (activeTravianAccount) {
      return await sendData(
        `/troops/${activeTravianAccount.id}/troops/${newRow.id}`,
        "PUT",
        newRow
      )
    }
  }

  const handleOwnTroopsInput = async (e) => {
    try {
      if (activeTravianAccount) {
        const troopsOverview = e.target.value

        let keys1 = []
        let keys2 = []
        let newRows = rows

        if (troopsOverview.includes('class="coordinatesGrid"')) {
          const parsedTroops = parseTroopsFromHTML(troopsOverview)
          console.log("parsed", parsedTroops)

          newRows = rows.map((row) => {
            return {
              ...row,
              ...parsedTroops[row.villageId]
            }
          })

          keys1 = Object.keys(parsedTroops)
          keys2 = rows.map((row) => `${row.villageId}`)
        } else {
          const parsedTroops = parseTroopsFromOverview(
            troopsOverview,
            settings.serverSize.value
          )
          console.log("parsed", parsedTroops)

          newRows = rows.map((row) => {
            return {
              ...row,
              ...parsedTroops[row.coordId]
            }
          })

          keys1 = Object.keys(parsedTroops)
          keys2 = rows.map((row) => `${row.coordId}`)
        }

        const commonKeys = keys1.filter((key) => keys2.includes(key))
        if (!commonKeys.length) {
          setSnackbar({
            children:
              "None of the villages in the pasted data match account villages from map.sql",
            severity: "error"
          })
        } else {
          setRows(newRows)
          // put the whole thing
          await sendData(`/troops/${activeTravianAccount.id}/troops`, "PUT", newRows)
          setSnackbar({ children: "Saved successfully", severity: "success" })
        }
      }
    } catch (error) {
      console.error(error)
      setSnackbar({
        children: `${error}`,
        severity: "error"
      })
    }
  }

  const TroopsToolbarElement = () => {
    return editable ? (
      <>
        <TravianAccountSelection
          me={me}
          activeTravianAccount={activeTravianAccount}
          setActiveTravianAccount={setActiveTravianAccount}
        />
        <Input
          style={{
            backgroundColor: "#444444",
            height: "50px",
            width: "200px",
            marginTop: "10px",
            marginLeft: "10px",
            paddingLeft: "8px",
            overflow: "hidden"
          }}
          aria-label="Paste own troops"
          multiline
          placeholder="Paste here to update..."
          onFocus={(e) => {
            e.target.value = ""
          }}
          onChange={handleOwnTroopsInput}
        />
        <span style={{ textAlign: "left", marginLeft: "10px" }}>
          Paste "Own troops" overview (in English)
          <br />
          OR source code to update.
          <br />
          Static means the crop amount of defs that are sent permanently away from that
          village and are not available for def calls. This needs to be updated manually.
        </span>
      </>
    ) : (
      <>
        <span style={{ cursor: "pointer" }}>
          <ArrowBackIcon onClick={returnToReferrer}>Back</ArrowBackIcon>
        </span>
        <Typography component="h1" variant="h5" marginLeft={"5px"}>
          {focusedTravianAccount?.playerName}
        </Typography>
        <span style={{ fontStyle: "italic" }}>
          {getTribeName(focusedTravianAccount?.tribe)}{" "}
          {focusedTravianAccount?.accountType}
        </span>
        <span style={{ marginLeft: "20px" }}>
          Updated at: {renderUpdatedAt({ row: { updatedAt } })}
        </span>
      </>
    )
  }

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            dense
            height={800}
            columns={getColumnsForTribe(
              focusedTravianAccount
                ? getTribeName(focusedTravianAccount.tribe)
                : activeTravianAccount?.tribe,
              editable
            )}
            rows={presetRows || rows}
            mutateRow={mutateRow}
            confirm={false}
            sortModel={[{ field: "crop", sort: "desc" }]}
            ToolbarElement={TroopsToolbarElement}
          />
          {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}
