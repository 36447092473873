import { useEffect, useState } from "react"
import { styled, createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
// import Badge from "@mui/material/Badge"
import MenuIcon from "@mui/icons-material/Menu"
import Menu from "@mui/material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
// import NotificationsIcon from "@mui/icons-material/Notifications"
import { MenuItem } from "@mui/material"
import { fetchData } from "../util/api"
import SideNav from "../components/SideNav"
import DashboardContent from "../content/Dashboard"
import SettingsContent from "../content/Settings"
import TroopsContent from "../content/account/Troops"
import IncomingsContent from "../content/dc/Incomings"
import ServerTime from "../components/ServerTime"
import DefPlanContent from "../content/dc/DefPlan"
import RosterV2Content from "../content/alliance/RosterV2"
import ReportIncomingsContent from "../content/account/ReportIncomings"
import TroopsOverviewContent from "../content/alliance/TroopsOverview"
import StatisticsContent from "../content/account/Statistics"
import StatisticsOverviewContent from "../content/alliance/StatisticsOverview"
import HammersContent from "../content/oc/Hammers"
import TargetsContent from "../content/oc/Targets"

const getContentComponent = (contentComponentName) => {
  switch (contentComponentName) {
    case "Settings":
      return SettingsContent
    case "Roster":
      return RosterV2Content
    case "Troops":
      return TroopsContent
    case "Incomings":
      return IncomingsContent
    case "Def plan":
      return DefPlanContent
    case "Report incomings":
      return ReportIncomingsContent
    case "Troops overview":
      return TroopsOverviewContent
    case "Statistics":
      return StatisticsContent
    case "Statistics overview":
      return StatisticsOverviewContent
    case "Hammers":
      return HammersContent
    case "Targets":
      return TargetsContent
    case "Dashboard":
    default:
      return DashboardContent
  }
}

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}Mastertool {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9)
        }
      })
    }
  })
)

const theme = createTheme({
  palette: {
    mode: "dark",
    text: { primary: "#bbb" },
    primary: {
      main: "#A0A9C4"
    },
    secondary: {
      main: "#9A377C"
    }
  },
  transitions: {
    duration: {
      enteringScreen: 20,
      leavingScreen: 20
    }
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#bbb"
        }
      }
    }
  }
})

export default function AppFrame() {
  const [open, setOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  const [settingsOpen, setSettingsOpen] = useState(false)
  const [settingsAnchor, setSettingsAnchor] = useState(null)
  const openSettings = (target) => {
    setSettingsOpen(true)
    setSettingsAnchor(target)
  }
  const closeSettings = () => {
    setSettingsOpen(false)
    setSettingsAnchor(null)
  }

  const [me, setMe] = useState("")
  const [settings, setSettings] = useState("")

  useEffect(() => {
    fetchData("/me", setMe)
    fetchData("/settings", setSettings)
  }, [])

  const [activeContent, setActiveContent] = useState(
    window.localStorage.getItem("activeContent") || "Dashboard"
  )

  const setAndSaveActiveContent = (activeContent) => {
    window.localStorage.setItem("activeContent", activeContent)
    setActiveContent(activeContent)
  }

  function PageContent({ me, settings }) {
    const ContentComponent = getContentComponent(activeContent)
    return <ContentComponent me={me} settings={settings} />
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px" // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {activeContent}
            </Typography>
            <ServerTime settings={settings} />
            <Typography
              component="h4"
              variant="body1"
              color="inherit"
              noWrap
              sx={{
                pr: "12px"
              }}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={(e) => {
                e.stopPropagation()
                openSettings(e.currentTarget)
              }}
            >
              {me.member?.nick || me.member?.user?.username || "Logging in..."}
            </Typography>
            <Menu
              id="settings"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              transitionDuration={0}
              anchorEl={settingsAnchor}
              open={settingsOpen}
              onClose={closeSettings}
            >
              <List>
                <MenuItem
                  onClick={() => {
                    closeSettings()
                    window.location.href = "/logout"
                  }}
                >
                  Logout
                </MenuItem>
              </List>
            </Menu>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1]
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <SideNav me={me} setActiveContent={setAndSaveActiveContent} />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto"
          }}
        >
          <Toolbar />
          {me && settings ? <PageContent me={me} settings={settings} /> : null}
          <Copyright sx={{ pt: 4 }} />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
