import React from "react"
import { useSearchParams } from "react-router-dom"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}Mastertool {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#363D60"
    },
    secondary: {
      main: "#9A377C"
    }
  }
})

export default function ErrorPage() {
  const [searchParams] = useSearchParams()
  const errorCode = parseInt(searchParams.get("error") || "500", 10)
  let errorText = "Server error"
  if (errorCode < 500) {
    errorText = "Client error"
  }
  if (errorCode === 403) {
    errorText = "Forbidden"
  }
  if (errorCode === 401) {
    errorText = "Unauthorized"
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Error
          </Typography>
          <Typography component="h4" variant="h4">
            {errorCode} - {errorText}.
          </Typography>
          <Typography component="h5" variant="h5">
            Please contact your admins.
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              window.localStorage.clear()
              window.location.href = "/app"
            }}
          >
            Dashboard
          </Button>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
