import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { Button, Input, Paper } from "@mui/material"
import { sendData } from "../../util/api"
import { useState } from "react"
import "react-dropdown/style.css"

function extractStatistics(statisticsPageSource) {
  const statisticsJSON = `[${
    statisticsPageSource
      .split("window.Travian.React.Statistics.render(")[1]
      .split(");")[0]
  }]`
  return JSON.parse(statisticsJSON)[0]
}

export default function StatisticsContent({ me, settings }) {
  const [statisticsJSON, setStatisticsJSON] = useState("")
  const [playerId, setPlayerId] = useState("")
  const [saveConfirmation, setSaveConfirmation] = useState("")

  const handleInput = async (e) => {
    setSaveConfirmation("")
    setStatisticsJSON(extractStatistics(e.target.value))
    const name = e.target.value.split('<div class="playerName">')[1].split("</div>")[0]
    setPlayerId(
      (me?.travianAccounts || []).find((account) => account.name === name)?.id || ""
    )
  }

  return (
    <Container maxWidth="" sx={{ mt: 12, mb: 12, ml: 12, mr: 12 }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={12} lg={12} xl={12} spacing={6}>
          <Paper
            sx={{
              p: 6,
              display: "flex",
              flexDirection: "column"
            }}
            variant="outlined"
          >
            <Grid item xs={12} md={12} lg={12} xl={12}>
              Paste general statistics page source code to send information:
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} mb={4}>
              <Input
                disabled={!(me?.travianAccounts || []).length}
                style={{
                  backgroundColor: "white",
                  width: "300px",
                  height: "90px",
                  overflow: "hidden",
                  verticalAlign: "top",
                  fontSize: "0.5em"
                }}
                aria-label="Paste general statistics page source code"
                multiline
                onFocus={(e) => {
                  e.target.value = ""
                }}
                onChange={handleInput}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} xl={12} mb={4}>
              {saveConfirmation}
            </Grid>

            <Button
              disabled={!statisticsJSON || !playerId}
              variant="outlined"
              onClick={async () => {
                await sendData(`/statistics/${playerId}`, "PUT", statisticsJSON)
                  .then((result) => {
                    setSaveConfirmation("Statistics saved.")
                  })
                  .catch((error) => setSaveConfirmation("Could not save statistics."))
                setStatisticsJSON("")
              }}
            >
              Confirm
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
