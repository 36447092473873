import { Button, Container, Grid, Input } from "@mui/material"
import MasterDataGrid from "../../components/MasterDataGrid"
import { useEffect, useState } from "react"
import { fetchData } from "../../util/api"

export default function TargetsContent({ me, settings }) {
  const [capitalPopLimits, setCapitalPopLimits] = useState({})
  const [boosterPopLimits, setBoosterPopLimits] = useState({})

  const setCapitalsOver = (allyId, limit) => {
    const newLimits = { ...capitalPopLimits }
    newLimits[allyId] = limit
    setCapitalPopLimits(newLimits)
  }
  const setBoostersOver = (allyId, limit) => {
    const newLimits = { ...boosterPopLimits }
    newLimits[allyId] = limit
    setBoosterPopLimits(newLimits)
  }

  const columns = [
    {
      field: "name",
      headerName: "Alliance",
      width: 120,
      editable: false
    },
    {
      field: "villageCount",
      headerName: "Villages",
      width: 100,
      editable: false
    },
    {
      field: "capitals",
      headerName: "Capitals over",
      width: 150,
      renderCell: (row) => {
        return (
          <>
            <Input
              value={row.capitalsOver}
              placeholder="0"
              inputProps={{ style: { textAlign: "right" } }}
              style={{
                width: "50px",
                padding: "5px"
              }}
              onChange={(e) => {
                setCapitalsOver(row.id, e.target.value)
              }}
            ></Input>{" "}
            pop
          </>
        )
      }
    },
    {
      field: "boosters",
      headerName: "Boosters over",
      width: 150,
      renderCell: (row) => {
        return (
          <>
            <Input
              value={row.boostersOver}
              placeholder="0"
              inputProps={{ style: { textAlign: "right" } }}
              style={{
                width: "50px",
                padding: "5px"
              }}
              onChange={(e) => {
                setBoostersOver(row.id, e.target.value)
              }}
            ></Input>{" "}
            pop
          </>
        )
      }
    },
    {
      field: "download",
      headerName: "",
      width: 200,
      renderCell: (row) => {
        const searchParams = new URLSearchParams()
        searchParams.append("allyId", row.id)
        searchParams.append("capitalsOver", capitalPopLimits[row.id] || 0)
        searchParams.append("boostersOver", boosterPopLimits[row.id] || 0)
        searchParams.toString()

        return (
          <Button
            onClick={() => {
              fetchData(`/map?${searchParams.toString()}`, () => {}, { blob: true })
            }}
          >
            Download targets
          </Button>
        )
      }
    }
  ]

  const [rows, setRows] = useState([])

  useEffect(() => {
    fetchData("/alliances/all", (res) => {
      setRows(res)
    })
  }, [])

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            dense
            height={800}
            columns={columns}
            rows={rows}
            confirm={false}
            sortModel={[{ field: "villageCount", sort: "desc" }]}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
