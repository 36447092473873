import Container from "@mui/material/Container"
import { useEffect, useState } from "react"
import { ScatterChart } from "@mui/x-charts/ScatterChart"
import { fetchData } from "../util/api"
import { Grid } from "@mui/material"
import Title from "../components/Title"

export default function DashboardContent({ me, settings }) {
  const [capitals, setCapitals] = useState([])

  useEffect(() => {
    if (
      (me?.roles || []).some((role) =>
        ["ADMIN", "DC", "OC", "MEMBER"].includes(role.accessType)
      )
    ) {
      fetchData("/travian-accounts/capitals", setCapitals, { useCache: true })
    }
  }, [me])

  const legendPlacement = {
    legend: {
      position: {
        vertical: "middle",
        horizontal: "right"
      },
      direction: "column"
    }
  }

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Title>{me?.allianceName || "Alliance"}</Title>
      <Grid container>
        <ScatterChart
          width={700}
          height={700}
          {...legendPlacement}
          sx={{
            "--ChartsLegend-rootOffsetX": "40px",
            "--ChartsLegend-rootOffsetY": "0px"
          }}
          xAxis={[{ min: -45, max: 160 }]}
          yAxis={[{ min: -45, max: 160 }]}
          series={[
            {
              label: "Off",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Off", "WWK", "WWR"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#8B0000"
            },
            {
              label: "Def",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital && ["Def"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#006400"
            },
            {
              label: "Hybrid",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Hybrid"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#8B008B"
            },
            {
              label: "Scout",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Scout"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#4169E1"
            },

            {
              label: "Ghost",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Ghost"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#B8860B"
            },
            {
              label: "Tech",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Tech"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#4A4A4A"
            },
            {
              label: "Support",
              data: capitals
                .filter(
                  (travianAccount) =>
                    travianAccount.capital &&
                    ["Support"].includes(travianAccount.accountType)
                )
                .map((travianAccount) => ({
                  x: travianAccount.capital?.xCoord,
                  y: travianAccount.capital?.yCoord,
                  id: travianAccount.id,
                  accountName: travianAccount.name
                })),
              valueFormatter: ({ x, y, accountName }) => `${accountName} (${x}|${y})`,
              color: "#808080"
            }
          ]}
        />
      </Grid>
    </Container>
  )
}
