function inServerTime(date, { locale, timeZone }) {
  if (!date || !locale || !timeZone) {
    return ""
  }
  try {
    return new Date(date).toLocaleString(locale.value, { timeZone: timeZone.value })
  } catch (e) {
    return ""
  }
}

function approximateDuration(date1, date2) {
  const duration = new Date()
  duration.setTime(Math.abs(new Date(date2).getTime() - new Date(date1).getTime()))
  const ONE_DAY = 24 * 60 * 60 * 1000
  const fullDays = Math.floor(duration.getTime() / ONE_DAY)
  return `${fullDays * 24 + duration.getUTCHours()}h${duration.getUTCMinutes()}`
}

/**
 * Calculates travel time in seconds on a Travian T4 (2019) map
 */
function getTravelTime(target, attacker, settings, map) {
  if (!target || !attacker || !settings) {
    return -1
  }
  // Distance on a torus surface
  let distance = Math.sqrt(
    Math.pow(
      Math.min(
        Math.abs(attacker.xCoord - target.xCoord),
        settings.serverSize.value * 2 + 1 - Math.abs(attacker.xCoord - target.xCoord)
      ),
      2
    ) +
      Math.pow(
        Math.min(
          Math.abs(attacker.yCoord - target.yCoord),
          settings.serverSize.value * 2 + 1 - Math.abs(attacker.yCoord - target.yCoord)
        ),
        2
      )
  )
  // Round as per Travian implementation
  distance = Math.round(distance * Math.pow(10, 5)) / Math.pow(10, 5)
  // Baseline speed
  let squaresPerSecond =
    (attacker.unitSpeed * settings.serverSpeed.value * attacker.arteSpeed) / 60 / 60
  // Return if distance under 20
  if (distance <= 20) {
    const time = distance / squaresPerSecond
    return Math.round(map ? (time * 100) / (100 + map) : time)
  }
  // No-TS part of travel
  let travelTime = 20 / squaresPerSecond
  // Reduce distance
  distance -= 20
  // Calculate TS and boots factor
  let factor = 1.0 + attacker.tournamentSquare * 0.2
  factor += (attacker.heroBoots || 0) / 100
  // Adjust speed
  squaresPerSecond *= factor
  // Compute remaining time
  travelTime += distance / squaresPerSecond
  return Math.round(map ? (travelTime * 100) / (100 + map) : travelTime)
}

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 60 / 60)
  let minutes = Math.floor((seconds - hours * 60 * 60) / 60)
  if (minutes < 10) {
    minutes = `0${minutes}`
  }
  return `${hours}h${minutes}`
}

export { inServerTime, approximateDuration, getTravelTime, formatTime }
