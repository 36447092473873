import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import { useEffect, useState } from "react"
import { ImmediateSelect } from "../../components/ImmediateSelect"
import MasterDataGrid from "../../components/MasterDataGrid"
import { StackedTime } from "../../components/StackedTime"
import { deleteData, fetchData, sendData } from "../../util/api"
import { approximateDuration, getTravelTime, inServerTime } from "../../util/time"
import DeleteIcon from "@mui/icons-material/Delete"
import { ConfirmDialog } from "../../components/ConfirmDialog"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { FormControlLabel, FormGroup, Switch } from "@mui/material"

export default function IncomingsContent({ me, settings }) {
  const [rows, setRows] = useState([])
  const [allianceSelection] = useState(
    JSON.parse(window.localStorage.getItem("allianceSelection") || "{}")
  )

  const [fakeFilter, setFakeFilter] = useState(
    JSON.parse(window.localStorage.getItem("incomingsFakeFilter") || "true")
  )

  const setAndSaveFakeFilter = (filterValue) => {
    window.localStorage.setItem("incomingsFakeFilter", JSON.stringify(filterValue))
    setFakeFilter(filterValue)
  }

  const [modifyAll, setModifyAll] = useState(
    JSON.parse(window.localStorage.getItem("incomingsModifyAll") || "false")
  )

  const setAndSaveModifyAll = (modifyAllValue) => {
    window.localStorage.setItem("incomingsModifyAll", JSON.stringify(modifyAllValue))
    setModifyAll(modifyAllValue)
  }

  const mutateRow = async (newRow) => {
    const oldRow = rows.find((row) => row.id === newRow.id)
    const diff = {}
    Object.keys(newRow).forEach((key) => {
      if (
        ["unitSpeed", "arteSpeed", "tournamentSquare", "heroBoots"].includes(key) &&
        newRow[key] !== oldRow[key]
      ) {
        diff[key] = newRow[key]
      }
    })
    const rowsToMutate =
      modifyAll && Object.keys(diff).length
        ? rows.filter(
            (row) =>
              row.attacker.xCoord === newRow.attacker.xCoord &&
              row.attacker.yCoord === newRow.attacker.yCoord
          )
        : []
    const rowMutations = rowsToMutate
      .filter((row) => row.id !== newRow.id)
      .map((row) => sendData(`/v2/incomings/${row.id}`, "PATCH", { ...row, ...diff }))
    rowMutations.push(sendData(`/v2/incomings/${newRow.id}`, "PATCH", newRow))
    await Promise.all(rowMutations)
    return {}
  }

  const [confirmDialogParams, setConfirmDialogParams] = useState(null)

  const updateRows = () => {
    fetchData(`/v2/incomings/active`, (incomings) => {
      setRows(
        incomings
          .filter((incoming) => allianceSelection[incoming.defender.allyName] !== false)
          .filter((incoming) => !(fakeFilter && incoming?.call === "Fake"))
          .map((incoming) => ({ ...incoming, heroBoots: incoming.heroBoots || 0 }))
      )
      // Temporary fix to remove duplicates
      // const filteredIncomings = {}
      // incomings.forEach((incoming) => {
      //   const temporaryId = `${incoming.arrival.substring(0, 19)}__${incoming.waves}__${
      //     incoming.attacker.xCoord
      //   }|${incoming.attacker.yCoord}`
      //   filteredIncomings[temporaryId] = incoming
      // })
      // setRows(Object.values(filteredIncomings))
    })
  }

  useEffect(updateRows, [allianceSelection, fakeFilter])

  // setInterval(updateRows, 60 * 1000)

  const deleteRow = async (row) => {
    await deleteData(`/v2/incomings/${row.id}`)
    fetchData(`/v2/incomings/active`, (incomings) => {
      setRows(
        incomings.filter(
          (incoming) => allianceSelection[incoming.defender.allyName] !== false
        )
      )
    })
  }

  const getSendTime = ({
    arrival,
    defender,
    attacker,
    unitSpeed,
    arteSpeed,
    tournamentSquare,
    heroBoots
  }) => {
    const travelSeconds = getTravelTime(
      defender,
      {
        ...attacker,
        unitSpeed,
        arteSpeed,
        tournamentSquare,
        heroBoots
      },
      settings
    )
    return new Date(new Date(arrival).getTime() - travelSeconds * 1000)
  }

  const getTotalWavesCount = ({ xCoord, yCoord }) => {
    return rows
      .filter((row) => row.attacker.xCoord === xCoord && row.attacker.yCoord === yCoord)
      .map((row) => row.waves)
      .reduce((prev, curr) => prev + curr, 0)
  }

  const columns = [
    {
      field: "arrival",
      headerName: "Arrival",
      width: 170,
      editable: false,
      valueFormatter: ({ value }) =>
        `in ${approximateDuration(value, new Date())} at ${inServerTime(
          value,
          settings
        )}`,
      renderCell: ({ row }) => (
        <>
          <div style={{ paddingRight: 10 }}>
            in {approximateDuration(row.arrival, new Date())} at
          </div>
          <StackedTime time={row.arrival} settings={settings} />
        </>
      )
    },
    {
      field: "waves",
      headerName: "X",
      width: 40,
      editable: false
    },
    {
      field: "attacker",
      headerName: "Attacker",
      width: 220,
      editable: false,
      valueGetter: ({ row }) =>
        `${row.attacker.playerName} (${row.attacker.xCoord}|${row.attacker.yCoord})`,
      renderCell: ({ row }) => (
        <>
          <div style={{ textAlign: "left" }}>
            {row.attacker.playerName} [{row.attacker.allyName}]
            <br />
            <a
              style={{ color: "lightgrey" }}
              href={`https://${settings?.travianUrl?.value}/karte.php?x=${row.attacker.xCoord}&y=${row.attacker.yCoord}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.attacker.villageName} ({row.attacker.xCoord}|{row.attacker.yCoord})
            </a>
          </div>
        </>
      )
    },
    {
      field: "totalWaves",
      headerName: "Total X",
      width: 60,
      editable: false,
      valueGetter: ({ row }) => {
        return getTotalWavesCount(row.attacker)
      }
    },
    {
      field: "defender",
      headerName: "Defender",
      width: 220,
      editable: false,
      valueGetter: ({ row }) =>
        `${row.defender.playerName} (${row.defender.xCoord}|${row.defender.yCoord})`,
      renderCell: ({ row }) => (
        <>
          <div style={{ textAlign: "left" }}>
            {row.defender.playerName} [{row.defender.allyName}]
            <br />{" "}
            <a
              style={{ color: "lightgrey" }}
              href={`https://${settings?.travianUrl?.value}/karte.php?x=${row.defender.xCoord}&y=${row.defender.yCoord}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.defender.villageName} ({row.defender.xCoord}|{row.defender.yCoord})
            </a>
          </div>
        </>
      )
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 200,
      editable: true
    },
    {
      field: "lastChecked",
      headerName: "Last checked",
      width: 110,
      editable: false,
      valueFormatter: ({ value }) => inServerTime(value, settings),
      renderCell: ({ row }) => (
        <StackedTime time={row.lastChecked || 0} settings={settings} />
      )
    },
    {
      field: "saveTime",
      headerName: "Save time",
      width: 110,
      editable: false,
      valueFormatter: ({ value }) => inServerTime(value, settings),
      renderCell: ({ row }) => <StackedTime time={row.saveTime} settings={settings} />
    },
    {
      field: "sendTime",
      headerName: "Send time",
      width: 110,
      editable: false,
      valueGetter: ({ row }) => inServerTime(getSendTime(row), settings),
      renderCell: (params) => (
        <StackedTime time={getSendTime(params.row)} settings={settings} />
      ),
      renderEditCell: (params) => (
        <StackedTime time={getSendTime(params.row)} settings={settings} />
      )
    },
    {
      field: "unitSpeed",
      headerName: "Unit",
      width: 90,
      editable: true,
      type: "number",
      headerAlign: "left",
      valueGetter: ({ row }) => row.unitSpeed,
      renderCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="unitSpeed"
          valueOptions={[3, 4, 5, 6, 7, 9, 10, 13, 14, 15, 16, 17, 19, 20, 22, 25]}
          mutateRow={mutateRow}
        />
      ),
      renderEditCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="unitSpeed"
          valueOptions={[3, 4, 5, 6, 7, 9, 10, 13, 14, 15, 16, 17, 19, 20, 22, 25]}
          mutateRow={mutateRow}
        />
      )
    },
    {
      field: "arteSpeed",
      headerName: "Arte",
      width: 90,
      editable: true,
      type: "number",
      headerAlign: "left",
      valueGetter: ({ row }) => row.arteSpeed,
      renderCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="arteSpeed"
          valueOptions={[0.33, 0.5, 0.67, 1, 1.5, 2]}
          mutateRow={mutateRow}
        />
      ),
      renderEditCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="arteSpeed"
          valueOptions={[0.33, 0.5, 0.67, 1, 1.5, 2]}
          mutateRow={mutateRow}
        />
      )
    },
    {
      field: "tournamentSquare",
      headerName: "TS",
      width: 90,
      editable: true,
      type: "number",
      headerAlign: "left",
      valueGetter: ({ row }) => row.tournamentSquare,
      renderCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="tournamentSquare"
          valueOptions={[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
          ]}
          mutateRow={mutateRow}
        />
      ),
      renderEditCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="tournamentSquare"
          valueOptions={[
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
          ]}
          mutateRow={mutateRow}
        />
      )
    },
    {
      field: "heroBoots",
      headerName: "Boots",
      width: 90,
      editable: true,
      type: "number",
      headerAlign: "left",
      valueGetter: ({ row }) => row.heroBoots,
      renderCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="heroBoots"
          valueOptions={[0, 25, 50, 75]}
          mutateRow={mutateRow}
        />
      ),
      renderEditCell: ({ row }) => (
        <ImmediateSelect
          row={row}
          column="heroBoots"
          valueOptions={[0, 25, 50, 75]}
          mutateRow={mutateRow}
        />
      )
    },
    {
      field: "call",
      headerName: "Call",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: ["-", "Fake", "Maybe", "Def", "Split", "Evade", "Done"]
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 40,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <>
            <ConfirmDialog
              params={confirmDialogParams}
              setOpen={setConfirmDialogParams}
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() =>
                setConfirmDialogParams({ yesAction: () => deleteRow({ id }) })
              }
              color="inherit"
            />
          </>
        ]
      }
    }
  ]

  function IncomingsToolbar() {
    return (
      <>
        <FormGroup>
          <FormControlLabel
            style={{ marginLeft: 10, marginTop: 10 }}
            control={
              <Switch
                checked={fakeFilter}
                onChange={() => setAndSaveFakeFilter(!fakeFilter)}
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            }
            label="Filter out determined fakes"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            style={{ marginLeft: 10, marginTop: 10 }}
            control={
              <Switch
                checked={modifyAll}
                onChange={() => setAndSaveModifyAll(!modifyAll)}
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            }
            label="Modify all rows from the same attacker"
          />
        </FormGroup>
      </>
    )
  }

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            height={800}
            columns={columns}
            rows={rows}
            mutateRow={mutateRow}
            confirm={false}
            sortModel={[{ field: "saveTime", sort: "desc" }]}
            ToolbarElement={IncomingsToolbar}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
