import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { v4 as uuidv4 } from "uuid"
import { useEffect, useState } from "react"
import MasterDataGrid from "../../components/MasterDataGrid"
import { deleteData, fetchData, sendData } from "../../util/api"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { ConfirmDialog } from "../../components/ConfirmDialog"

export default function RosterV2Content({ me, settings }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const getInviteLink = () => {
    return `${window.location.href.split("/app")[0]}/login?guildId=${me.activeGuildId}`
  }

  const handleClick = () => {
    setSnackbarOpen(true)
    navigator.clipboard.writeText(getInviteLink())
  }

  const [rows, setRows] = useState([])
  const [guildMembers, setGuildMembers] = useState([])

  useEffect(() => {
    fetchData("/travian-accounts", setRows)
    if ((me?.roles || []).some((role) => ["ADMIN"].includes(role.accessType))) {
      fetchData("/guild/members", setGuildMembers)
    }
  }, [me])

  const mutateRow = async (newRow) => {
    return await sendData(`/travian-accounts/${newRow.id}`, "PUT", newRow)
  }

  const deleteRow = async (row) => {
    await deleteData(`/travian-accounts/${row.id}`)
    fetchData("/travian-accounts", setRows)
  }

  const [dropdownFilter, setDropdownFilter] = useState(
    JSON.parse(window.localStorage.getItem("rosterAccountDropdownFilter") || "true")
  )

  const setAndSaveDropdownFilter = (filterValue) => {
    window.localStorage.setItem(
      "rosterAccountDropdownFilter",
      JSON.stringify(filterValue)
    )
    setDropdownFilter(filterValue)
  }

  const addGuildMemberToTravianAccount = async ({ travianAccount, guildMember }) => {
    travianAccount.discordUsers.push(guildMember)
    await mutateRow(travianAccount)
    fetchData("/travian-accounts", setRows)
  }

  const removeGuildMemberFromTravianAccount = async ({ travianAccount, id }) => {
    travianAccount.discordUsers = travianAccount.discordUsers.filter(
      (guildMember) => guildMember.user.id !== id
    )
    await mutateRow(travianAccount)
    fetchData("/travian-accounts", setRows)
  }

  const [confirmDialogParams, setConfirmDialogParams] = useState(null)

  const columns = [
    {
      field: "techs",
      headerName: "Techs",
      width: 60,
      editable: false,
      valueGetter: ({ row }) => `${row.techFor}${row.name}`,
      valueFormatter: () => ""
    },
    {
      field: "name",
      headerName: "Account (IGN)",
      width: 180,
      editable: true,
      renderCell: ({ row }) => (
        <>
          <div>
            {row.techFor ? (
              <div
                style={{
                  textAlign: "left",
                  fontSize: "0.8em",
                  fontStyle: "italic"
                }}
              >
                {row.techFor} tech
                <br />
              </div>
            ) : null}
            <span style={{ marginLeft: row.techFor ? "5px" : "initial" }}>
              {row.name}
            </span>
          </div>
        </>
      )
    },
    {
      field: "discordUsers",
      headerName: "Discord users",
      width: 250,
      editable: true,
      sortComparator: (a, b) => {
        console.log(a)
        console.log(b)
        return (a || []).length - (b || []).length
      },
      renderCell: ({ row }) => (
        <>
          <div style={{ textAlign: "left", margin: "5px" }}>
            {(row.discordUsers || []).map((guildMember) => (
              <div key={guildMember.user.id} style={{ margin: "2px 0px" }}>
                {guildMember.nick ||
                  guildMember.user.global_name ||
                  guildMember.user.username}{" "}
                <HighlightOffIcon
                  fontSize="2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    removeGuildMemberFromTravianAccount({
                      travianAccount: row,
                      id: guildMember.user.id
                    })
                  }
                />
                <br />
              </div>
            ))}
          </div>
        </>
      ),
      renderEditCell: ({ row }) => (
        <>
          <div style={{ textAlign: "left", margin: "5px" }}>
            {(row.discordUsers || []).map((guildMember) => (
              <div key={guildMember.user.id} style={{ margin: "2px 0px" }}>
                {guildMember.nick ||
                  guildMember.user.global_name ||
                  guildMember.user.username}{" "}
                <HighlightOffIcon
                  fontSize="2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    removeGuildMemberFromTravianAccount({
                      travianAccount: row,
                      id: guildMember.user.id
                    })
                  }
                />
                <br />
              </div>
            ))}
            <FormControl style={{ minWidth: 200, marginTop: 5 }}>
              <InputLabel id="add-user-to-account-select-label">Add</InputLabel>
              <Select
                labelId="add-user-to-account-select-label"
                id="add-user-to-account-select"
                value=""
                label="Add"
                onChange={(e) => {
                  if (
                    !(me?.roles || []).some((role) => ["ADMIN"].includes(role.accessType))
                  ) {
                    return
                  }
                  const { value } = e.target
                  if (value.sync) {
                    fetchData("/guild/members?sync=true", setGuildMembers)
                  } else {
                    addGuildMemberToTravianAccount({
                      travianAccount: row,
                      guildMember: value
                    })
                  }
                }}
                style={{ height: 50 }}
              >
                {guildMembers
                  .filter(
                    (guildMember) =>
                      !dropdownFilter ||
                      !rows.some((row) =>
                        row.discordUsers.some((gm) => gm.user.id === guildMember.user.id)
                      )
                  )
                  .sort((a, b) =>
                    (a.nick || a.user.global_name || a.user.username).localeCompare(
                      b.nick || b.user.global_name || b.user.username
                    )
                  )
                  .map((guildMember) => (
                    <MenuItem key={guildMember.user.id} value={guildMember}>
                      {guildMember.nick ||
                        guildMember.user.global_name ||
                        guildMember.user.username}
                    </MenuItem>
                  ))}
                <MenuItem value={{ sync: true }}>[Sync...]</MenuItem>
              </Select>
            </FormControl>
          </div>
        </>
      )
    },
    {
      field: "tribe",
      headerName: "Tribe",
      width: 100,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Roman", "Teuton", "Gaul", "Egyptian", "Hun"]
    },
    {
      field: "accountType",
      headerName: "Account type",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        "Off",
        "Def",
        "WWK",
        "WWR",
        "Scout",
        "Ghost",
        "Hybrid",
        "Tech",
        "Support"
      ]
    },
    {
      field: "techFor",
      headerName: "Tech for",
      width: 180,
      editable: true,
      type: "singleSelect",
      valueOptions: ["", ...rows.map((row) => row.name).sort()]
    },
    {
      field: "group",
      headerName: "Group",
      width: 100,
      editable: true
    },
    {
      field: "alliance",
      headerName: "Alliance",
      width: 100,
      editable: false
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 300,
      editable: true
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 40,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <>
            <ConfirmDialog
              params={confirmDialogParams}
              setOpen={setConfirmDialogParams}
            />
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() =>
                setConfirmDialogParams({ yesAction: () => deleteRow({ id }) })
              }
              color="inherit"
            />
          </>
        ]
      }
    }
  ]

  function InsertActionElement() {
    return (
      <>
        <Button
          variant="outlined"
          style={{ marginLeft: 10, marginTop: 10 }}
          startIcon={<AddIcon />}
          onClick={() =>
            setRows([
              {
                id: uuidv4(),
                name: "",
                players: "",
                discordUsers: [],
                tribe: "",
                accountType: "",
                group: "",
                alliance: "",
                notes: ""
              },
              ...rows
            ])
          }
        >
          Add account
        </Button>
        <FormGroup>
          <FormControlLabel
            style={{ marginLeft: 10, marginTop: 10 }}
            control={
              <Switch
                checked={dropdownFilter}
                onChange={() => setAndSaveDropdownFilter(!dropdownFilter)}
                inputProps={{ "aria-label": "controlled" }}
                color="warning"
              />
            }
            label="Filter users that are already assigned to an account"
          />
        </FormGroup>
      </>
    )
  }

  return (
    <Container maxWidth="" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={12} lg={12} xl={12}>
          <Typography
            component="h6"
            variant="h6"
            style={{ marginLeft: 10, marginTop: 10, marginRight: 15 }}
          >
            Invite link:
          </Typography>
          <TextField
            variant="filled"
            size="small"
            style={{ width: 700 }}
            defaultValue={getInviteLink()}
            InputProps={{
              readOnly: true
            }}
          />
          <IconButton onClick={handleClick} color="primary">
            <ContentCopyIcon />
          </IconButton>
          <Snackbar
            message="Copied to clipboard"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <MasterDataGrid
            height={800}
            columns={columns}
            rows={rows}
            getRowHeight={() => "auto"}
            mutateRow={mutateRow}
            confirm={false}
            sortModel={[{ field: "techs", sort: "asc" }]}
            ToolbarElement={InsertActionElement}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
