import { v5 as uuidv5 } from "uuid"

const attackerVillages = [
  100100, 100501, 100502, 100503, 100903, 100904, 100905, 101303, 101304, 101305, 101306,
  101706, 102947, 104150, 104545, 104548, 104551, 104954, 105350, 105351, 105751, 105752,
  105753, 105754, 105755, 106153, 106154, 106556, 107757, 120228, 123090, 6460, 90482,
  99701
]
const defenderVillages = [
  55916, 58341, 60301, 66323, 66380, 67946, 69577, 71190, 71191, 71990, 71992, 72391,
  72393, 73158, 73559, 73560, 73593, 74794, 74807, 75595, 76795, 77593, 77994, 79147,
  79204, 79994, 80351, 82010, 82403, 82804
]

export function generateTestIncomings() {
  const incomings = []
  for (let i = 0; i < 4; i++) {
    const secondsToArrival = 17.5 * 60 * 60 * 1000 + Math.random() * 60 * 60 * 1000
    const arrival = new Date(Date.now() + secondsToArrival)

    const waves = 4

    const randomAttackerIndex = Math.floor(Math.random() * attackerVillages.length)
    const attacker = { coordId: attackerVillages[randomAttackerIndex] }

    const randomDefenderIndex = Math.floor(Math.random() * defenderVillages.length)
    const defender = { coordId: defenderVillages[randomDefenderIndex] }

    incomings.push({
      arrival,
      waves,
      attacker,
      defender
    })
  }

  return incomings
}

const INCOMINGS_NS = "3d018b6a-54b7-440b-97ee-8a9835668634"

export function getRowHash({ arrival, attacker, defender }) {
  return uuidv5(
    `${new Date(arrival * 1000).getTime()};${defender.coordId};${attacker.coordId}`,
    INCOMINGS_NS
  )
}

function readServerTimeSeconds(source) {
  const serverTimeSeconds = source
    .split("Server time:")[1]
    .split('value="')[1]
    .split('"')[0]
  if (serverTimeSeconds.length !== 10) {
    throw new Error("Could not read server time.")
  }
  return parseInt(serverTimeSeconds)
}

function readTravelSeconds(source) {
  return parseInt(source.split('value="')[1].split('"')[0])
}

function readIncoming(source) {
  if (!source.includes('href="/karte.php?d=')) {
    return undefined
  }
  let type = ""
  if (
    source
      .split("\n")
      .find(
        (line) =>
          line.includes("<table") &&
          line.includes("troop_details") &&
          line.includes("inAttack")
      )
  ) {
    type = "attack"
  } else if (
    source
      .split("\n")
      .find(
        (line) =>
          line.includes("<table") &&
          line.includes("troop_details") &&
          line.includes("inRaid")
      )
  ) {
    type = "raid"
  }
  if (!type) {
    return undefined
  }
  return {
    type,
    arrival: readTravelSeconds(source.substring(source.indexOf('class="infos"'))),
    attacker: { coordId: source.split("/karte.php?d=")[1].split('"')[0] },
    defender: { coordId: source.split("/karte.php?d=")[2].split('"')[0] }
  }
}

export function parseIncomingsFromSourceCode(source) {
  const uniqueIncomings = []
  const waves = {}

  const incomingsSource = source
    .substring(
      source.indexOf("rallyPointOverviewContainer"),
      source.indexOf("sidebarBoxActiveVillage")
    )
    .split("/table")

  let landingOrderSeparationMillis = 0
  let currentAttacker = ""
  let currentSecond = 0

  for (let i = 0; i < incomingsSource.length; i++) {
    const incoming = readIncoming(incomingsSource[i])
    if (incoming) {
      // Keep track of second
      if (currentSecond !== incoming.arrival) {
        // Change of second = reset milliseconds and attacker
        landingOrderSeparationMillis = 0
        currentAttacker = ""
      }
      currentSecond = incoming.arrival

      // Keep track of attacker
      if (incoming.attacker.coordId !== currentAttacker) {
        // Change of attacker = add to milliseconds
        landingOrderSeparationMillis++
      }
      currentAttacker = incoming.attacker.coordId

      // Modify arrival time
      incoming.arrival += landingOrderSeparationMillis / 1000

      // Attacker+modified arrival time now identifies the incoming wave count
      const currentRowHash = getRowHash(incoming)
      if (!waves[currentRowHash]) {
        waves[currentRowHash] = 0
        uniqueIncomings.push(incoming)
      }
      waves[currentRowHash]++
    }
  }

  const serverTimeSeconds = readServerTimeSeconds(source)

  return uniqueIncomings.map((incoming) => ({
    ...incoming,
    arrival: new Date(
      Math.round((incoming.arrival + serverTimeSeconds) * 1000)
    ).toISOString(),
    waves: waves[getRowHash(incoming)]
  }))
}
