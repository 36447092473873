import * as React from "react"
import { useTheme } from "@mui/material/styles"
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, ReferenceLine } from "recharts"
import { formatTime } from "../util/time"

export default function TravelGraph({ timeUntilHit, timeUntil90Percent, troopBatches }) {
  const theme = useTheme()

  return (
    <React.Fragment>
      <ResponsiveContainer>
        <BarChart
          data={troopBatches}
          margin={{
            top: 16,
            right: 20,
            bottom: 0,
            left: 24
          }}
          barGap={0}
          barSize={4}
        >
          <XAxis
            dataKey="timeToTarget"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            type="number"
            domain={[0, timeUntilHit + 2 * 60 * 60]}
            allowDataOverflow
            tickFormatter={formatTime}
          />
          <YAxis
            dataKey="crop"
            stroke={theme.palette.text.secondary}
            style={theme.typography.body2}
            type="number"
            hide
          />
          <Bar
            isAnimationActive={false}
            dataKey="crop"
            fill={theme.palette.primary.main}
            scale="time"
          />
          <ReferenceLine x={timeUntil90Percent} stroke="blue" />

          <ReferenceLine
            x={timeUntilHit}
            stroke="red"
            label={`hit @${formatTime(timeUntilHit)}`}
          />
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  )
}
