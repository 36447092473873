import * as React from "react"
import {
  DataGrid,
  GridToolbarContainer,
  useGridApiRef,
  getGridDefaultColumnTypes,
  DEFAULT_GRID_COL_TYPE_KEY
} from "@mui/x-data-grid"
import Snackbar from "@mui/material/Snackbar"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Alert from "@mui/material/Alert"

const dummyMutate = async (newRow) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (newRow[0]?.trim() === "") {
        reject("First column cannot be empty")
      } else {
        resolve(newRow)
      }
    }, 200)
  })

function computeMutation(newRow, oldRow) {
  if (Object.keys(newRow).some((key) => newRow[key] !== oldRow[key])) {
    let mutation = ""
    for (const key of Object.keys(newRow)) {
      mutation += `\n${key}: ${newRow[key]}`
    }
    return mutation
  }
  return null
}

export default function MasterDataGrid({
  dense,
  height = 0,
  columns,
  rows,
  rowHeight,
  getRowHeight,
  editMode = "row",
  mutateRow = dummyMutate,
  confirm = true,
  columnVisibilityModel,
  sortModel,
  filterModel,
  ToolbarElement,
  sx = {}
}) {
  function Toolbar() {
    return (
      <GridToolbarContainer>
        <ToolbarElement />
      </GridToolbarContainer>
    )
  }

  const noButtonRef = React.useRef(null)
  const [promiseArguments, setPromiseArguments] = React.useState(null)

  const [snackbar, setSnackbar] = React.useState(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  const apiRef = useGridApiRef()

  React.useEffect(() => {
    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent("rowClick", ({ row }) => {
      if (apiRef.current.getRowMode(row.id) === "view") {
        apiRef.current.startRowEditMode(row)
      } else {
        apiRef.current.stopRowEditMode(row)
      }
    })
  }, [apiRef])

  const sendMutation = React.useCallback(
    async ({ newRow, oldRow, reject, resolve }) => {
      try {
        // Make the HTTP request to save in the backend
        const response = await mutateRow(newRow)
        setSnackbar({ children: "Saved successfully", severity: "success" })
        resolve(response)
        setPromiseArguments(null)
      } catch (error) {
        setSnackbar({ children: `Validation error: ${error}`, severity: "error" })
        reject(oldRow)
        setPromiseArguments(null)
      }
    },
    [mutateRow]
  )

  const processRowUpdate = React.useCallback(
    async (newRow, oldRow) =>
      await new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow)
        if (mutation || editMode === "cell") {
          if (!!confirm) {
            // Save the arguments to resolve or reject the promise later
            setPromiseArguments({ resolve, reject, newRow, oldRow })
          } else {
            // Send immediately
            sendMutation({ resolve, reject, newRow, oldRow })
          }
        } else {
          resolve(oldRow) // Nothing was changed
        }
      }),
    [confirm, editMode, sendMutation]
  )

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments
    resolve(oldRow) // Resolve with the old row to not update the internal state
    setPromiseArguments(null)
  }

  const handleYes = async () => {
    await sendMutation(promiseArguments)
  }

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  }
  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null
    }

    const { newRow, oldRow } = promiseArguments
    const mutation = computeMutation(newRow, oldRow)

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent dividers>
          {`Pressing 'Yes' will update row ${mutation}`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const defaultColumnTypes = getGridDefaultColumnTypes()
  const defaultFilterOperators =
    defaultColumnTypes[DEFAULT_GRID_COL_TYPE_KEY].filterOperators

  const customFilters = [
    {
      label: "is not",
      value: "isNot",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null
        }
        return (value) => {
          return !value?.value.toLowerCase().includes(filterItem.value.toLowerCase())
        }
      },
      getValueAsString: (value) => `is not ${value}`,
      InputComponent: defaultFilterOperators[0].InputComponent,
      InputComponentProps: defaultFilterOperators[0].InputComponentProps
    }
  ]

  return (
    <div style={{ height: height || "auto", width: "100%" }}>
      {renderConfirmDialog()}
      <DataGrid
        apiRef={apiRef}
        rowHeight={dense ? 25 : rowHeight || undefined}
        getRowHeight={getRowHeight}
        autoHeight={!height}
        columns={columns.map((col) => ({
          ...col,
          filterOperators: col.filterOperators ?? [
            ...defaultColumnTypes[col.type ?? DEFAULT_GRID_COL_TYPE_KEY].filterOperators,
            ...customFilters
          ]
        }))}
        rows={rows}
        editMode={editMode}
        disableRowSelectionOnClick={true}
        initialState={{
          columns: {
            columnVisibilityModel
          },
          sorting: {
            sortModel
          },
          filter: {
            filterModel
          }
        }}
        slots={ToolbarElement ? { toolbar: Toolbar } : null}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={() => {}}
        sx={sx}
      />
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  )
}
