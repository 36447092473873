import { useState } from "react"
import { useGridApiContext } from "@mui/x-data-grid"
import { MenuItem, Select } from "@mui/material"

export function ImmediateSelect({ row, column, valueOptions, mutateRow }) {
  const apiRef = useGridApiContext()
  const [open, setOpen] = useState(false)

  const updateValues = (e) => {
    if (open && e.target.tagName === "LI") {
      const newRow = { ...row }
      newRow[column] =
        typeof valueOptions[0] === "number"
          ? parseFloat(e.target.innerText)
          : e.target.innerText
      apiRef.current.setEditCellValue({
        id: row.id,
        field: column,
        value: newRow[column]
      })
      apiRef.current.updateRows([{ ...newRow }])
    }
  }

  return (
    row[column] !== undefined && (
      <>
        <Select
          style={{ width: "100%" }}
          id={`${column}-select`}
          value={row[column]}
          label=""
          onOpen={(e) => {
            setOpen(true)
            apiRef.current.startCellEditMode({ id: row.id, field: column })
          }}
          onClose={(e) => {
            setOpen(false)
            mutateRow(row)
            apiRef.current.stopCellEditMode({ id: row.id, field: column })
          }}
          onFocusCapture={updateValues}
          onPointerOver={updateValues}
        >
          {valueOptions.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </>
    )
  )
}
