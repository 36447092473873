import { Button, FormControl, Input, InputLabel } from "@mui/material"
import { useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { assembleTroopBatches } from "../util/defPlan"

export default function DefCallControls({
  settings,
  target,
  row,
  getCropInDefCalls,
  timeUntilHit,
  addDefCall
}) {
  const cropOnTime =
    Math.floor(
      (assembleTroopBatches({ target, troops: row?.troops || {}, settings })
        .filter((troopBatch) => troopBatch.timeToTarget < timeUntilHit)
        .reduce((sum, troopBatch) => sum + troopBatch.crop, 0) -
        getCropInDefCalls(row.id)) /
        1000
    ) * 1000

  const [amount, setAmount] = useState(cropOnTime || 0)
  // const [notes, setNotes] = useState("")

  return (
    <>
      <FormControl style={{ marginTop: 6 }}>
        <InputLabel id="def-call-amount-label">Amount</InputLabel>
        <Input
          style={{ backgroundColor: "#444444", margin: 10, padding: 8 }}
          type="number"
          aria-label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </FormControl>
      {/* <FormControl style={{ marginTop: 6 }}>
        <InputLabel id="def-call-notes-label">Notes</InputLabel>
        <Input
          style={{ backgroundColor: "#444444", margin: 10, padding: 8 }}
          aria-label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </FormControl> */}
      <Button
        variant="outlined"
        disabled={amount <= 0}
        onClick={() => {
          setAmount(cropOnTime - amount)
          addDefCall({
            id: uuidv4(),
            incomingId: target.id,
            amount,
            arrival: target.arrival,
            travianAccountId: row.id,
            travianAccount: row
          })
        }}
      >
        Add
      </Button>
    </>
  )
}
