import { coordinatesToMapId } from "./mapId"

const getConsumption = (troops, tribe) => {
  let factors
  switch (tribe) {
    case "Roman":
      factors = [1, 1, 1, 2, 3, 4, 3, 6, 1]
      break

    case "Teuton":
      factors = [1, 1, 1, 1, 2, 3, 3, 6, 1]
      break

    case "Gaul":
      factors = [1, 1, 2, 2, 2, 3, 3, 6, 1]
      break

    case "Egyptian":
      factors = [1, 1, 1, 2, 2, 3, 3, 6, 1]
      break

    case "Hun":
      factors = [1, 1, 2, 2, 2, 3, 3, 6, 1]
      break

    default:
      console.log("could not compute consumption")
      console.log(troops)
      return ""
  }
  return (
    (troops.troop1 || 0) * factors[0] +
      (troops.troop2 || 0) * factors[1] +
      (troops.troop3 || 0) * factors[2] +
      (troops.troop4 || 0) * factors[3] +
      (troops.troop5 || 0) * factors[4] +
      (troops.troop6 || 0) * factors[5] +
      (troops.troop7 || 0) * factors[6] +
      (troops.troop8 || 0) * factors[7] +
      (troops.troop9 || 0) * factors[8] || 0
  )
}

const getColumnsForTribe = (tribe, editable) => {
  const columns = [
    {
      field: "villageName",
      headerName: "Village",
      width: 180,
      editable: false,
      valueGetter: (params) =>
        `${params.row.villageName}${params.row.capital ? " (capital)" : ""}`
    },
    {
      field: "coords",
      headerName: "Coords",
      width: 90,
      editable: false,
      valueGetter: (params) => `(${params.row.xCoord}|${params.row.yCoord})`
    }
  ]
  const troopColumns = [
    {
      field: "troop1",
      width: 70,
      editable
    },
    {
      field: "troop2",
      width: 70,
      editable
    },
    {
      field: "troop3",
      width: 70,
      editable
    },
    {
      field: "troop4",
      width: 70,
      editable
    },
    {
      field: "troop5",
      width: 70,
      editable
    },
    {
      field: "troop6",
      width: 70,
      editable
    },
    {
      field: "troop7",
      width: 70,
      editable
    },
    {
      field: "troop8",
      width: 70,
      editable
    },
    {
      field: "troop9",
      width: 70,
      editable
    }
  ]
  let headers
  switch (tribe) {
    case "Roman":
      headers = ["Lego", "Praet", "Imp", "EL", "EI", "EC", "Ram", "Cata", "Sena"]
      break

    case "Teuton":
      headers = ["Club", "Spear", "Axe", "Scout", "Pala", "TK", "Ram", "Cata", "Chief"]
      break

    case "Gaul":
      headers = ["Phal", "Sword", "Scout", "TT", "Druid", "Haed", "Ram", "Cata", "Chief"]
      break

    case "Egyptian":
      headers = [
        "Slave",
        "Ash",
        "Khopesh",
        "Scout",
        "Anhur",
        "Chariot",
        "Ram",
        "Cata",
        "Chief"
      ]
      break

    case "Hun":
      headers = [
        "Merc",
        "Bow",
        "Scout",
        "Steppe",
        "Marks",
        "Mara",
        "Ram",
        "Cata",
        "Chief"
      ]
      break

    default:
      break
  }
  if (headers) {
    for (let i = 0; i < troopColumns.length; i++) {
      troopColumns[i] = {
        ...troopColumns[i],
        headerName: headers[i]
      }
    }
    columns.push(...troopColumns)
  }
  columns.push(
    ...[
      {
        field: "crop",
        headerName: "Crop",
        width: 80,
        valueGetter: (params) => getConsumption(params.row, tribe)
      },
      {
        field: "tournamentSquare",
        headerName: "TS",
        width: 70,
        editable
      },
      {
        field: "queReady",
        headerName: "Que ready",
        width: 80,
        type: "boolean",
        editable
      },
      {
        field: "worldOffRank",
        headerName: "#World",
        width: 70,
        editable: true,
        valueGetter: (params) => params.row.worldOffRank || ""
      },
      {
        field: "allyOffRank",
        headerName: "#Ally",
        width: 70,
        editable: true,
        valueGetter: (params) => params.row.allyOffRank || ""
      },
      { field: "static", headerName: "Static", width: 70, editable },
      {
        field: "notes",
        headerName: "Notes",
        width: 500,
        editable: true
      }
    ]
  )
  return columns
}

const parseReadableNumber = (str) => {
  return str
    .split("")
    .filter((char) => {
      return ["−", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(char)
    })
    .map((char) => {
      if (char === "−") {
        return "-"
      }
      return char
    })
    .join("")
}

const readTroopLines = (lines) => {
  const troopsObject = {
    troop1: 0,
    troop2: 0,
    troop3: 0,
    troop4: 0,
    troop5: 0,
    troop6: 0,
    troop7: 0,
    troop8: 0,
    troop9: 0
  }
  Object.keys(troopsObject).forEach((troopId, i) => {
    const troopCount = parseInt(lines[i].split(">")[1].split("</")[0])
    troopsObject[troopId] = troopCount
  })
  return troopsObject
}

// 7. BP IS HERE	14821	2913	0	0	202	351	0	1	0	0	0
const readTroopLine = (line) => {
  const troopCounts = line.split(/[\s]+/).slice(-11)
  const troopsObject = {
    troop1: 0,
    troop2: 0,
    troop3: 0,
    troop4: 0,
    troop5: 0,
    troop6: 0,
    troop7: 0,
    troop8: 0,
    troop9: 0
  }
  Object.keys(troopsObject).forEach((troopId, i) => {
    const troopCount = parseInt(troopCounts[i])
    troopsObject[troopId] = troopCount
  })
  return troopsObject
}

const parseTroopsFromHTML = (overviewSource) => {
  const lines = overviewSource.split("\n")
  if (!lines.length) {
    return {}
  }
  // Parse troops into villages
  const villageTroopMap = {}
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].includes("<td") && lines[i].includes('class="villageName"')) {
      let villageId = ""
      if (lines[i].includes("?newdid=")) {
        villageId = lines[i].split("?newdid=")[1].split("&amp;")[0]
      } else if (lines[i + 1].includes("?newdid=")) {
        villageId = lines[i + 1].split("?newdid=")[1].split("&amp;")[0]
      }
      villageTroopMap[villageId] = readTroopLines(
        lines.slice(i + 1, i + 20).filter((line) => line.includes("<td"))
      )
    }
  }
  return villageTroopMap // villageId to troops object map, so that it can be projected on the rows and sent in the PUT.
}

const parseTroopsFromOverview = (overview, serverSize) => {
  const lines = overview
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean)

  if (!lines.length) {
    return {}
  }

  // Construct villages array
  const villageCoordIds = []
  let parseVillages = false
  let parseTroops = false
  for (let i = 3; i < lines.length - 1; i++) {
    // Start after the VILLAGES header
    const last3Lines = lines.slice(i - 3, i)
    if (
      last3Lines.some((line) => line.toLowerCase().startsWith("population:")) &&
      last3Lines.some((line) => line.toLowerCase().startsWith("loyalty:")) &&
      last3Lines.some((line) => line.toLowerCase().startsWith("villages"))
    ) {
      parseVillages = true
    }
    // Stop after the next empty line
    if (parseVillages && (!(lines[i] || "").trim() || !lines[i + 1].includes("|"))) {
      break
    }
    // Parse villages to be used
    if (parseVillages) {
      const xCoordPart = parseReadableNumber(lines[i + 1].split("|")[0])
      const yCoordPart = parseReadableNumber(lines[i + 1].split("|")[1])
      const xCoord = parseInt(xCoordPart)
      const yCoord = parseInt(yCoordPart)
      villageCoordIds.push(coordinatesToMapId(xCoord, yCoord, serverSize * 2 + 1))
      i++
    }
  }

  // Parse troops into villages
  const villageTroopMap = {}
  for (let i = 7; i < lines.length - 1; i++) {
    // Start after the Village header
    const last7Lines = lines.slice(i - 7, i)
    if (
      last7Lines.some((line) => line.toLowerCase().startsWith("troops")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("own troops")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("troops in villages")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("smithy")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("hospital")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("training")) &&
      last7Lines.some((line) => line.toLowerCase().startsWith("village"))
    ) {
      parseTroops = true
    }
    if (parseTroops) {
      for (let j = 0; j < villageCoordIds.length; j++) {
        // console.log(lines[i + j])
        villageTroopMap[villageCoordIds[j]] = readTroopLine(lines[i + j])
      }
      break
    }
  }

  return villageTroopMap // coordId to troops object map, so that it can be projected on the rows and sent in the PUT.
}

export {
  getConsumption,
  getColumnsForTribe,
  parseReadableNumber,
  readTroopLines,
  readTroopLine,
  parseTroopsFromHTML,
  parseTroopsFromOverview
}
